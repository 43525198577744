import { ColDef } from "@ag-grid-community/core";
import { Chip, Divider, MenuItem } from "@material-ui/core";
import { ExpandMore } from "@mui/icons-material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Avatar, Box, FormControl, Link, Select, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro-v5";
import { CustomTypography } from "@next/components/custom-typography";
import snackbarUtils from "@next/utils/snackbarUtils";
import { t } from "assets/configi18n/i18n";
import { formatDate } from "helpers/formatDate";
import { AppTheme } from "layout/AppTheme";
import React, { useContext, useMemo } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { getRfqDetails } from "services/workspace/workspace.selectors";
import { toggleShowQuotationVariance } from "../../redux/selectors";
import { borderStyle, ellipsisText, textColor, textSize } from "./quote-comparison-constants";
import { QuoteComparisonCurrencyField } from "./quote-comparison-currency-field";
import { quoteComparisonContext } from "./quote-comparison.context";
import { calculateSupplierTotalPriceSum } from "./quote-comparison.utils";
import { Field, QCPartSupplier, QCSupplier } from "./types";

const QuotationShowVariance = () => {
  const showQuotationVariance = useSelector(toggleShowQuotationVariance);
  return { showQuotationVariance };
};

const convertDateTimeString = (dateTimeString) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(dateTimeString);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const month = monthNames[monthIndex];
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

const NameAndQuantityHeaderCell = () => {
  const {
    gridData: { config },
  } = useContext(quoteComparisonContext);

  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      flexDirection="column"
      border={borderStyle}
      borderBottom="none"
      borderTop="none"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" width="100%" p="8px" pb="0px" pt="4px" />

      <Box
        display="flex"
        alignItems="center"
        width="100%"
        height="32px"
        pl="8px"
        pr="8px"
        borderTop={borderStyle}
        borderBottom={borderStyle}
      >
        {config?.options?.part_fields?.map((part_field, index) => {
          if (!config?.selected?.part_fields.includes(part_field?.slug)) {
            return null;
          }

          return (
            <Box
              key={index}
              width={`${100 / config?.selected?.part_fields?.length}%`}
              textAlign="left"
              borderRight={
                index === config?.selected?.part_fields.length - 1 ||
                config?.selected?.part_fields?.length === 1
                  ? undefined
                  : borderStyle
              }
              paddingLeft="8px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="noWrap"
              display="block"
            >
              <CustomTypography variant="caption" style={{ fontWeight: "bold" }}>
                {part_field?.name}
              </CustomTypography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const NameAndQuantityCell = (params: any) => {
  const {
    gridData: { config },
  } = useContext(quoteComparisonContext);

  return (
    <Box
      display="flex"
      width="100%"
      borderLeft={borderStyle}
      borderBottom={borderStyle}
      pl="8px"
      pr="8px"
    >
      {config?.options?.part_fields?.map((part_field, index) => {
        if (!config?.selected?.part_fields.includes(part_field?.slug)) return null;

        return (
          <Box
            width={`${100 / config?.selected?.part_fields?.length}%`}
            textAlign="left"
            borderRight={
              index === config?.selected?.part_fields.length - 1 ||
              config?.selected?.part_fields?.length === 1
                ? undefined
                : borderStyle
            }
            paddingLeft="8px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="noWrap"
            display="block"
          >
            <CopyToClipboard
              text={params?.data?.part_fields?.[part_field?.slug] || ""}
              onCopy={() => snackbarUtils.toast(t("common:copiedToClipboard"))}
            >
              <CustomTypography variant="caption">
                {params?.data?.part_fields?.[part_field?.slug]}
              </CustomTypography>
            </CopyToClipboard>
          </Box>
        );
      })}
    </Box>
  );
};

const SupplierHeaderCell = ({
  id,
  name,
  picture_url,
  quoted_currency,
  quoted_at,
  converted_price,
  lead_time,
}: Partial<QCSupplier>) => {
  const {
    selectedData,
    gridData: { config },
    selectAllPartsBySupplier,
    isAwardedRfq,
  } = useContext(quoteComparisonContext);
  const rfqDetails = useSelector(getRfqDetails);
  const selectedSupplierTotalPriceSum = useMemo(
    () => calculateSupplierTotalPriceSum(selectedData?.selectedSuppliersParts, id),
    [selectedData?.selectedSuppliersParts, id]
  );

  const hasQuotationTemplate = useMemo(() => {
    return Boolean(
      rfqDetails.quotations.find((quotation) => quotation.company_info.company_pk === id)?.rfq_info
        ?.quotation_extra_fields?.length > 0
    );
  }, [rfqDetails.quotations]);

  return (
    <Box display="flex" width="100%">
      <Box
        display="flex"
        width="calc(100% - 55px)"
        height="100%"
        flexDirection="column"
        border="1px solid #E0E0E0"
        justifyContent="space-between"
        style={{ backgroundColor: "white" }}
      >
        <Box p="8px">
          <Box display="flex">
            <Avatar
              variant="rounded"
              style={{ width: "80px", height: "80px", borderWidth: 1 }}
              src={picture_url}
            >
              {name}
            </Avatar>

            <Box ml="12px" display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="body1">{name}</Typography>

              <Typography variant="body2" color="textSecondary">
                {t("workspaceNext:quoteComparison:onDateInCurrency", {
                  date: formatDate(quoted_at, "LL"),
                  currency: quoted_currency,
                })}
              </Typography>

              {!isAwardedRfq ? (
                <Box mt="6px">
                  <Typography variant="body2">
                    <Link
                      href="#"
                      onClick={() => id && selectAllPartsBySupplier(id)}
                      data-pid="linkQCSelectAllParts"
                    >
                      {t("workspaceNext:quoteComparison:selectAll")}
                    </Link>
                  </Typography>
                </Box>
              ) : null}
            </Box>

            <Box
              position="absolute"
              right="60px"
              top="6px"
              style={{ cursor: "grab" }}
              data-pid="iconQCDragAndDrop"
            >
              <Tooltip title="Drag to the left or right to re-order">
                <DragIndicatorIcon />
              </Tooltip>
            </Box>
          </Box>

          <Box>
            <Box mt="8px" display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex">
                <Typography
                  style={{
                    display: "inline",
                    fontWeight: "bold",
                  }}
                  variant="body2"
                >
                  <QuoteComparisonCurrencyField value={converted_price?.total_price || 0} />
                </Typography>

                <Box ml="4px">
                  <Typography variant="body2" color="textSecondary">
                    {t("workspaceNext:quoteComparison:totalQuote")}
                  </Typography>
                </Box>
              </Box>
              {hasQuotationTemplate ? null : (
                <Typography variant="body2">
                  <Trans
                    i18nKey="workspaceNext:quoteComparison:leadTime"
                    components={[<b />]}
                    values={{
                      lead_time,
                    }}
                  />
                </Typography>
              )}
            </Box>

            <Box mt="8px" display="flex">
              <Typography
                style={{
                  color: AppTheme.palette.primary.main,
                  display: "inline",
                  fontWeight: "bold",
                }}
                variant="body2"
              >
                <QuoteComparisonCurrencyField value={selectedSupplierTotalPriceSum || 0} />
              </Typography>

              <Box ml="4px">
                <Typography variant="body2" color="textSecondary">
                  {t("workspaceNext:quoteComparison:totalSelectedFees")}
                </Typography>
              </Box>
            </Box>

            <Box mt="8px" display="flex">
              <Typography
                style={{
                  display: "inline",
                  fontWeight: "bold",
                }}
                variant="body2"
              >
                <QuoteComparisonCurrencyField value={converted_price?.fixed_fees || 0} />{" "}
              </Typography>

              <Box ml="4px">
                <Typography variant="body2" color="textSecondary">
                  {t("workspaceNext:quoteComparison:fixedFees")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          width="100%"
          height="32px"
          p="8px"
          borderTop={borderStyle}
          borderBottom={borderStyle}
        >
          {config?.options?.quotation_fields.map((quotation_field, index) => {
            if (!config?.selected?.quotation_fields.includes(quotation_field?.slug)) {
              return null;
            }

            const isPriceField = checkIsPriceField(quotation_field?.slug);

            return (
              <Box
                width={`${100 / config?.selected?.quotation_fields?.length}%`}
                borderRight={
                  index === config?.selected?.quotation_fields?.length - 1 ||
                  config?.selected?.quotation_fields?.length === 1
                    ? undefined
                    : borderStyle
                }
                textAlign={isPriceField ? "right" : "left"}
                paddingLeft={isPriceField ? undefined : "8px"}
                paddingRight={isPriceField ? "8px" : undefined}
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                <CustomTypography variant="caption" style={{ fontWeight: "bold" }}>
                  {isPriceField
                    ? `${quotation_field?.name} (${config?.selected?.currency})`
                    : quotation_field?.name}
                </CustomTypography>
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box style={{ backgroundColor: "#FAFAFA", width: "55px", border: "none" }} />
    </Box>
  );
};

const SupplierCell = (params: any) => {
  const {
    gridData: { config },
    isAwardedRfq,
  } = useContext(quoteComparisonContext);

  const quotationFields = params?.value?.quotation_fields;

  return (
    <Box display="flex" width="100%">
      <Box
        display="flex"
        width="calc(100% - 55px)"
        height="32px"
        borderLeft={borderStyle}
        borderRight={borderStyle}
        style={{
          backgroundColor: params?.value?.selected ? `${AppTheme.palette.primary.main}1A` : "unset",
          cursor: isAwardedRfq ? "unset" : "pointer",
        }}
        borderBottom={borderStyle}
      >
        {config?.options?.quotation_fields.map((quotation_fields, index) => {
          if (!config?.selected?.quotation_fields.includes(quotation_fields?.slug)) {
            return null;
          }

          const isPriceField = checkIsPriceField(quotation_fields?.slug);

          return (
            <Box
              width={`${100 / config?.selected?.quotation_fields?.length}%`}
              borderRight={
                index === config?.selected?.quotation_fields?.length - 1 ||
                config?.selected?.quotation_fields?.length === 1
                  ? undefined
                  : borderStyle
              }
              textAlign={isPriceField ? "right" : "left"}
              paddingLeft={isPriceField ? undefined : "8px"}
              paddingRight={isPriceField ? "8px" : undefined}
              data-slug={quotation_fields?.slug}
            >
              {quotationFields?.total_price !== null ? (
                <CopyToClipboard
                  text={
                    isPriceField
                      ? quotationFields?.converted_price?.[quotation_fields?.slug]
                      : quotationFields?.[quotation_fields?.slug]
                  }
                  onCopy={() => snackbarUtils.toast(t("common:copiedToClipboard"))}
                >
                  <CustomTypography
                    variant="caption"
                    tooltipTitle={quotationFields?.total_price === null ? "" : undefined}
                  >
                    {renderQuotationField(quotation_fields?.slug, quotationFields)}
                  </CustomTypography>
                </CopyToClipboard>
              ) : null}
            </Box>
          );
        })}
      </Box>

      <Box
        style={{
          backgroundColor: "#FAFAFA",
          width: "55px",
          height: "50px",
          border: "none",
        }}
      />
    </Box>
  );
};

const renderQuotationField = (quotationFieldSlug: string, quotationFields: any) => {
  const isPriceField = checkIsPriceField(quotationFieldSlug);

  if (isPriceField) {
    return (
      <QuoteComparisonCurrencyField
        value={quotationFields?.converted_price?.[quotationFieldSlug]}
        hideCurrency
      />
    );
  } else {
    return <Typography variant="caption">{quotationFields?.[quotationFieldSlug]}</Typography>;
  }
};

export const checkIsPriceField = (quotationFieldSlug: string) =>
  quotationFieldSlug === "price" || quotationFieldSlug === "total_price";

export const defaultColumnDef = {
  width: 150,
};

const getSuppliersColumns = (suppliers: QCSupplier[] = []) => {
  const columns: ColDef[] = [];

  suppliers.forEach((supplier: QCSupplier) => {
    columns.push({
      field: "id",
      headerName: supplier?.name,
      headerComponent: () => <SupplierHeaderCell {...supplier} />,
      valueGetter: (params: any) =>
        params?.data?.suppliers?.find((item: any) => item.id === supplier?.id),
      cellRenderer: SupplierCell,
      width: 448,
    });
  });

  return columns;
};

const renderSupplierSelection = (row: any, onChangeHandler: (event: Event, row: any) => void) => {
  const {
    gridData: { config },
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useContext(quoteComparisonContext);
  const suppliers: QCSupplier[] = row.suppliers.map((part_supplier: QCPartSupplier) => {
    const qc_supplier = config?.options?.suppliers.find((item) => item.id === part_supplier.id);
    return {
      ...qc_supplier,
      converted_price: { ...part_supplier.quotation_fields.converted_price },
    };
  });

  const selected_supplier: QCSupplier | undefined = row.suppliers.find(
    (part_supplier: QCPartSupplier) => {
      if (part_supplier?.selected) {
        const qc_supplier: QCSupplier = config?.options?.suppliers.find(
          (item) => item.id === part_supplier.id
        );
        return {
          ...qc_supplier,
          converted_price: {
            ...part_supplier.quotation_fields.converted_price,
          },
        };
      }
    }
  );

  let selected_item: number[] | any = selected_supplier?.id ? [selected_supplier?.id] : [];

  return (
    <FormControl fullWidth>
      <Select
        sx={{
          boxShadow: "none",
          "& .MuiSelect-select": {
            paddingLeft: 0,
          },
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
        }}
        fullWidth
        multiple
        disableUnderline
        IconComponent={ExpandMore}
        value={selected_item}
        onChange={(event: any) => onChangeHandler(event, row)}
        color="primary"
        renderValue={(value: number) => {
          const {
            gridData: { config },
          } = useContext(quoteComparisonContext);
          const selected_supplier: QCSupplier = config?.options?.suppliers?.find(
            (item) => item.id === value?.[0]
          );
          if (!value) return <></>;
          return (
            <Box display="flex" alignItems="center" gap="8px">
              <img height="30px" width="30px" src={selected_supplier?.picture_url} />
              <CustomTypography
                variant="caption"
                maxWidth="150px"
                tooltipTitle={selected_supplier?.name ? selected_supplier?.name : ""}
              >
                {selected_supplier?.name}
              </CustomTypography>
            </Box>
          );
        }}
      >
        {suppliers.length > 0 && (
          <Typography
            fontWeight="600"
            variant="body2"
            sx={{
              paddingLeft: "16px",
              color: AppTheme.palette.text.primary,
              letterSpacing: "0.25px",
              padding: "5px 16px",
            }}
          >
            {row?.part_fields?.name}
          </Typography>
        )}
        {suppliers.map((supplier, ind) => (
          <MenuItem key={ind} value={supplier.id} style={{ justifyContent: "space-between" }}>
            <CustomTypography
              variant="caption"
              maxWidth="150px"
              tooltipTitle={supplier.name ? supplier.name : ""}
            >
              {supplier.name}
            </CustomTypography>
            <QuoteComparisonCurrencyField
              value={supplier?.converted_price?.price || 0}
              hideCurrency
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const getQuoteComparisonColumns = ({ suppliers }: { suppliers: any }): ColDef[] => {
  return [
    {
      field: "id",
      headerComponent: () => <NameAndQuantityHeaderCell />,
      cellRenderer: NameAndQuantityCell,
      width: 402,
      pinned: "left",
      suppressMovable: true,
      lockPinned: true,
      lockPosition: true,
      lockVisible: true,
    },
    {
      field: "EMPTY",
      headerName: "",
      headerComponent: () => (
        <Box width="100%" height="100%" style={{ backgroundColor: "#FAFAFA" }} />
      ),
      cellStyle: { background: "#FAFAFA", borderTop: "0px", height: "50px" },
      width: 48,
      suppressMovable: true,
      lockPinned: true,
      lockPosition: true,
      lockVisible: true,
    },
    ...getSuppliersColumns(suppliers),
  ];
};

const getVarianceChip = (variation: number) => {
  const chipStyles = {
    green: {
      color: "#FF3E32",
      background: "rgba(255, 62, 50, 0.10)",
    },
    red: {
      color: "#0D9658",
      background: "rgba(18, 214, 125, 0.10)",
    },
    default: {
      color: "unset",
      background: "rgba(0, 0, 0, 0.10)",
    },
  };

  let chipStyle = undefined;
  if (variation === 0) {
    chipStyle = chipStyles["default"];
  } else if (variation > 0) {
    chipStyle = chipStyles["green"];
  } else {
    chipStyle = chipStyles["red"];
  }

  const variationText = variation !== 0 ? `${variation > 0 ? "+" : ""}${variation} %` : "——";

  return (
    <Chip
      label={variationText}
      size="small"
      style={{
        cursor: "pointer",
        height: "21px",
        color: chipStyle?.color || "unset",
        backgroundColor: chipStyle?.background || "rgba(0, 0, 0, 0.10)",
      }}
    />
  );
};

const renderPriceVariationCell = (params) => {
  const {
    gridData: { config },
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useContext(quoteComparisonContext);
  const selectedSupplier: QCPartSupplier = params?.row?.suppliers?.find(
    (supplier) => supplier.selected
  );

  const { showQuotationVariance } = QuotationShowVariance();
  if (!selectedSupplier) {
    // No supplier is selected yet. Render empty component
    return <></>;
  }
  if (selectedSupplier) {
    //if supplier is selected, destruct relevent fields
    const { converted_price } = selectedSupplier?.quotation_fields;
    if (!showQuotationVariance) {
      // Hide variance only show price
      return (
        <Box
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            width: "100%",
            padding: "0 10px",
          }}
        >
          <Typography fontSize={textSize}>
            <QuoteComparisonCurrencyField hideCurrency value={converted_price?.price || 0} />
          </Typography>
        </Box>
      );
    }

    // show quotation variance and price
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "0 10px",
        }}
      >
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                borderRadius: "16px",
                color: "black",
                backgroundColor: "white",
                boxShadow:
                  "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)",
              },
            },
          }}
          title={
            selectedSupplier.variation ? (
              <Box>
                <Typography sx={{ padding: "10px", color: textColor }}>
                  {t("workspaceNext:quoteComparison:previousSelectedUnitPrice")}
                </Typography>
                <Divider />
                <Box padding="10px">
                  <Typography
                    fontWeight="500"
                    paddingBottom="5px"
                    fontSize="20px"
                    letterSpacing="0.15px"
                  >
                    <QuoteComparisonCurrencyField
                      value={selectedSupplier?.previous_rfq?.previous_rfq_total_price || 0}
                      hideCurrency
                    />{" "}
                    $ {config?.selected?.currency}
                  </Typography>
                  <Typography paddingBottom="5px" style={{ color: textColor }}>
                    {selectedSupplier?.previous_rfq?.previous_rfq_supplier}
                  </Typography>
                  <Typography paddingBottom="5px" fontSize={textSize}>
                    <Link
                      sx={{
                        color: "#2682FD",
                      }}
                      underline="none"
                      target="_blank"
                      href={selectedSupplier?.previous_rfq?.previous_rfq_url}
                    >
                      {selectedSupplier?.previous_rfq?.previous_rfq_name}
                    </Link>
                  </Typography>
                  <Typography variant="caption" color="#0000008A">
                    {convertDateTimeString(selectedSupplier?.previous_rfq?.previous_rfq_created_at)}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box>
                <Typography sx={{ padding: "10px", color: textColor }}>
                  {t("workspaceNext:quoteComparison:noData")}
                </Typography>
              </Box>
            )
          }
        >
          {getVarianceChip(selectedSupplier.variation)}
        </Tooltip>
        <Typography fontSize={textSize}>
          <QuoteComparisonCurrencyField hideCurrency value={converted_price?.price || 0} />
        </Typography>
      </Box>
    );
  }
};

const renderTotalPriceRecapView = (params) => {
  const selectedSupplier = params?.row?.suppliers?.find((supplier) => supplier.selected);
  if (selectedSupplier) {
    return (
      <Box display={"flex"} justifyContent={"end"} width={"100%"}>
        <QuoteComparisonCurrencyField
          hideCurrency
          value={selectedSupplier?.quotation_fields?.converted_price?.total_price || 0}
        />
      </Box>
    );
  }
  return <></>;
};

const prepareRecapQuoteColumns = (config): GridColDef[] => {
  const columns: GridColDef[] = [];
  config.options.quotation_fields.forEach((field: Field) => {
    if (!config?.selected?.quotation_fields?.includes(field?.slug)) {
      return;
    }

    if (checkIsPriceField(field?.slug)) {
      columns.push({
        field: field.slug,
        headerName: `${field.name} (${config?.selected?.currency})`,
        width: 200,
        renderCell: (params) =>
          field.slug === "price"
            ? renderPriceVariationCell(params)
            : renderTotalPriceRecapView(params),
      });
    } else {
      columns.push({
        field: field.slug,
        headerName: field.name,
        width: 100,
        renderCell: (params) => {
          const selectedSupplier = params.row.suppliers.find((supplier) => supplier.selected);
          return <>{selectedSupplier?.quotation_fields[params.field]}</>;
        },
      });
    }
  });

  return columns;
};

const setColumnsWidth = (columnIndex: number) => {
  if (columnIndex == 0) {
    return 300;
  } else if (columnIndex == 2) {
    return 100;
  } else {
    return 200;
  }
};
const preparePartsFieldColumn = (config): GridColDef[] => {
  const columns: GridColDef[] = [];
  config.options.part_fields.forEach((part_field, index) => {
    if (!config?.selected?.part_fields?.includes(part_field?.slug)) return;
    columns.push({
      field: part_field?.slug,
      headerName: part_field?.name,
      width: setColumnsWidth(index),
      renderCell: (params) => {
        if (index === 1) {
          return (
            <Tooltip title={params.row?.part_fields?.[params.field]}>
              <span style={ellipsisText}>{params.row?.part_fields?.[params.field]}</span>
            </Tooltip>
          );
        }
        return params.row?.part_fields?.[params.field];
      },
    });
  });

  return columns;
};

export const getQuoteComparisonRecapColumns = (onChangeHandler, config): GridColDef[] => {
  return [
    ...preparePartsFieldColumn(config),
    {
      field: "quotes",
      headerName: t("workspaceNext:quoteComparisonHeaders:quotes"),
      width: 105,
      valueGetter: (params) => params?.row?.suppliers?.length,
    },
    {
      field: "suppliers",
      headerName: t("workspaceNext:quoteComparisonHeaders:selectedSupplier"),
      width: 300,
      align: "left",
      renderCell: (params) => renderSupplierSelection(params.row, onChangeHandler),
    },
    ...prepareRecapQuoteColumns(config),
    {
      field: "lead_time",
      headerName: t("workspaceNext:quoteComparisonHeaders:leadtime"),
      width: 300,
      renderCell: (params) => {
        const {
          gridData: { config },
          // eslint-disable-next-line react-hooks/rules-of-hooks
        } = useContext(quoteComparisonContext);
        const rfqDetails = useSelector(getRfqDetails);
        const selectedSupplier = params?.row?.suppliers?.filter((supplier) => supplier.selected);
        if (selectedSupplier && selectedSupplier.length > 0) {
          const filteredSelectedSupplier = config.options.suppliers.filter(
            (supplier) => supplier.id == selectedSupplier[0].id
          );
          const standardLeadTime = filteredSelectedSupplier[0]?.lead_time;
          const rfqPart = rfqDetails.quotations
            .find((quotation) => {
              return quotation.company_info.company_pk === selectedSupplier[0].id;
            })
            ?.parts?.find((part) => part.part_id === params.row.id);

          const hasCustomTemplate = Boolean(rfqPart?.quotation_extra_fields);
          if (hasCustomTemplate) return <></>;

          return standardLeadTime;
        }
        return <></>;
      },
    },
  ];
};
