import React from "react";
import { Box, Grid, Paper, Theme, Typography } from "@mui/material";
import logoBlack from "assets/img/logoHorizontalBlack.svg";
import { Topic } from "@next/modules/workspace/components/questions-answers/topic/topic";
import { TopicType } from "@next/modules/workspace/redux";
import { useSelector } from "react-redux";
import { selectQATopicLoading } from "@next/modules/workspace/redux/selectors";
import { CustomCircularProgress } from "../custom-circular-progress";
import { createStyles, makeStyles } from "@mui/styles";

type StyleProps = {
  backgroundColor?: string;
  hideTitleComponent?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      position: "relative",
      backgroundColor: ({ backgroundColor }) =>
        backgroundColor ? backgroundColor : theme.palette.grey[100],
      minHeight: "100vh",
      boxSizing: "border-box",
    },
    container: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    logo: {
      paddingTop: 20,
      paddingLeft: 18,
      paddingBottom: 20,
    },
    mainCompContainer: {
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: "none",
      position: "sticky",
      top: "68px",
      height: ({ hideTitleComponent }) => `calc(100vh - ${hideTitleComponent ? "128px" : "128px"})`,
    },
    poweredByText: {
      position: "fixed",
      right: 20,
      bottom: 16,
      color: theme.palette.text.disabled,
      "& img": {
        width: 58,
      },
    },
  })
);

interface Props {
  SideBarComponent: React.ReactNode;
  TitleComponent?: React.ReactNode;
  AccordionTitleComponent?: React.ReactNode;
  question?: TopicType;
  qaToken: string;
  hideAxyaBrand?: boolean;
  backgroundColor?: string;
  hideTitleComponent?: boolean;
}

const PortalPageLayout: React.FC<Props> = ({
  SideBarComponent,
  TitleComponent,
  AccordionTitleComponent,
  question,
  qaToken,
  hideAxyaBrand,
  backgroundColor,
  hideTitleComponent,
}) => {
  const classes = useStyles({ backgroundColor, hideTitleComponent });
  const questionLoading = useSelector(selectQATopicLoading);

  return (
    <div className={classes.root}>
      {!hideTitleComponent ? (
        <div className={classes.logo}>{TitleComponent || <Box height="40px" />}</div>
      ) : null}

      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={7}>
            {SideBarComponent}
          </Grid>

          <Grid item xs={12} md={5}>
            <Paper className={classes.mainCompContainer}>
              {questionLoading ? (
                <CustomCircularProgress />
              ) : question ? (
                <Topic
                  topic={question}
                  initiallyOpen
                  publicToken={qaToken}
                  showToastOnMessage={false}
                  TitleComponent={AccordionTitleComponent}
                  showExpandIcon={false}
                  isPortal={true}
                  disableAccordion={true}
                />
              ) : null}
            </Paper>
          </Grid>
        </Grid>
      </div>

      {!hideAxyaBrand ? (
        <Box className={classes.poweredByText}>
          <Typography variant="caption">
            Powered by <img src={logoBlack} alt="Axya logo" />
          </Typography>
        </Box>
      ) : null}
    </div>
  );
};

export default PortalPageLayout;
