import { Theme } from "@mui/material";
import { AppTheme } from "layout/AppTheme";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [`& input[type="number"]`]: {
        "-moz-appearance": "textfield",
      },
      [`& input[type="number"]::-webkit-outer-spin-button`]: {
        "-webkit-appearance": "none",
        margin: 0,
      },
      [`& input[type="number"]::-webkit-inner-spin-button`]: {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    loaderContainer: {
      display: "grid",
      placeItems: "center",
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      height: "100%",
      width: "100%",
      zIndex: 1000,
      backgroundColor: theme.palette.common.white,
    },
    header: {
      position: "sticky",
      top: 0,
      backgroundColor: theme.palette.common.white,
      zIndex: 10,
    },
    rfqDeleteIcon: {
      color: theme.palette.common.black,
    },
    headerLeftContainer: {
      display: "flex",
      alignItems: "center",
      // width: "100%",
      gap: theme.spacing(1),
      color: AppTheme.palette.primary.main,
    },
    rfqFormContainer: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
      padding: theme.spacing(5),
    },
    columnHeading: {
      fontWeight: 700,
      color: theme.palette.common.black,
      margin: theme.spacing(1.25, 0),
    },
    partRow: {
      display: "flex",
      alignItems: "center",
    },
    pointer: {
      cursor: "pointer",
    },
    sectionDivider: {
      margin: theme.spacing(6, -6.5),
    },
    row: {
      display: "flex",
      gap: theme.spacing(3),
    },
    closeIconBtn: {
      color: theme.palette.common.black,
      padding: theme.spacing(0.875),
    },
    infoText: {
      color: theme.palette.action.active,
    },
    rfqAttachmentBtn: {
      height: 40,
    },
    infoIcon: {
      marginLeft: theme.spacing(1.25),
      color: theme.palette.text.disabled,
      marginTop: theme.spacing(-0.25),
    },
    footer: {
      position: "sticky",
      bottom: 0,
      backgroundColor: theme.palette.common.white,
      display: "flex",
      flexDirection: "column",
      zIndex: 10,
    },
    preLine: {
      whiteSpace: "pre-line",
    },
    syncIcon: {
      color: theme.palette.text.secondary,
    },
    lastListItem: {
      marginBottom: 0,
    },
    listItem: {
      marginBottom: theme.spacing(1.5),
    },
    additionalDetailsAccordionRoot: {
      width: "100%",
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 24,
      padding: "12px 0px",
      "&:before": {
        display: "none",
      },
      "&.Mui-expanded": {
        margin: 0,
      },
      "&.MuiAccordion-root": {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
      },
    },
    publicSupplierContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
      marginBottom: theme.spacing(1.5),
    },
    labelIconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(0.5),
      width: "32px",
      height: "32px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "6px",
      color: theme.palette.background.default,
    },
    labelContainer: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
  })
);
