import React from "react";

import { frontendUrl } from "urls";
import AppLayout from "layout/AppLayout";
import { OrdersPage } from "@next/modules/orders/pages/orders-page";
import { CustomRoute } from "./types";
import { OrderDetailPage } from "@next/modules/orders/pages/order-detail-page/order-detail-page";
import AuthLayout from "layout/AuthLayout";
import { OrderDetailPortalPage } from "@next/modules/orders/pages/order-detail-portal-page/order-detail-portal-page";

const ORDERS_ROUTES: CustomRoute[] = [
  {
    // /orders
    layout: AppLayout,
    path: frontendUrl.orders,
    key: "orders",
    exact: true,
    component: () => <OrdersPage />,
  },
  {
    // /orders/:id
    layout: AppLayout,
    path: `${frontendUrl.orders}/:pk`,
    key: "orderDetail",
    exact: true,
    component: () => <OrderDetailPage />,
  },
  {
    layout: AuthLayout,
    showAxyaLogo: true,
    path: `${frontendUrl.portal}/orders/:orderToken/:qaToken`,
    key: "portal",
    exact: true,
    component: (props: any) => <OrderDetailPortalPage {...props} />,
    allowAuthenticated: true,
  },
];

export default ORDERS_ROUTES;
