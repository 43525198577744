import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ActionPanelType,
  AddOrderPartsInput,
  CreateOrderInput,
  DeleteOrderFileInput,
  DeleteOrderInput,
  DeleteShipmentPartInput,
  FetchBulkFilesUploadReportInput,
  FetchOrderInput,
  FetchOrdersInput,
  FetchPortalOrderInput,
  LineItemType,
  Order,
  OrderCounts,
  OrderFollowUp,
  OrderLineItem,
  OrderLineItemChanges,
  OrderStateCounts,
  OrderSupplier,
  OrdersState,
  ReviewRevisionProposalInput,
  RevisionProposal,
  SupplierLineItem,
  SupplierLineItemCounts,
  SupplierLineItemStateCounts,
  UpdateDraftOrderLineItemsInput,
  UpdateOrderDeliveryDatesInput,
  UpdateOrderFollowupInput,
  UpdateOrderInput,
  UpdateOrderLineItemsInput,
  UpdateOrderShippingConfirmationInput,
  UpdateOrderTargetedProfilesInput,
  UpdatePortalOrderAcknowledgeInput,
  UpdatePortalOrderInput,
  UpdatePortalOrderLineItemsInput,
  UploadBulkFilesInput,
  UploadOrderFileInput,
} from "./types";
import { GenericUser } from "@next/modules/profile/redux";
import { GridSelectionModel } from "@mui/x-data-grid-pro";
import { UploadReportStatus } from "@next/redux/types";

export const initialState: OrdersState = {
  ordersLoading: false,
  ordersError: undefined,
  orders: [],
  ordersCounts: {
    count: 0,
    active_count: 0,
    completed_count: 0,
  },
  ordersStatusCounts: {
    updated_count: 0,
    completed_count: 0,
    at_risk_count: 0,
    shipped_count: 0,
    on_track_count: 0,
    late_count: 0,
    total_count: 0,
    draft_count: 0,
  },
  ordersLastQuery: undefined,
  ordersSuppliers: [],

  supplierLineItems: [],
  supplierLineItemsLoading: false,
  supplierLineItemsError: undefined,
  supplierLineItemsCounts: {
    count: 0,
    active_count: 0,
    shipped_count: 0,
    cancelled_count: 0,
  },
  supplierLineItemsStatusCounts: {
    active_count: 0,
    updated_count: 0,
    at_risk_count: 0,
    on_track_count: 0,
    late_count: 0,
  },

  orderLoading: false,
  orderError: undefined,
  order: undefined,

  createOrderLoading: false,
  createOrderError: undefined,

  updateOrderLoading: false,
  updateOrderError: undefined,

  deleteOrderLoading: false,
  deleteOrderError: undefined,

  updateOrderFollowupLoading: false,
  updateOrderFollowupError: undefined,

  uploadOrderFileLoading: false,
  uploadOrderFileError: undefined,

  deleteOrderFileLoading: false,
  deleteOrderFileError: undefined,

  portalOrderLoading: false,
  portalOrderError: undefined,
  portalOrder: undefined,

  updatePortalOrderLoading: false,
  updatePortalOrderError: undefined,

  updateOrderLineItemsLoading: false,
  updateOrderLineItemsError: undefined,

  updateOrderTargetedProfilesLoading: false,
  updateOrderTargetedProfilesError: undefined,

  updatePortalOrderAcknowledgementLoading: false,
  updatePortalOrderAcknowledgementError: undefined,

  updateSupplierPOAcknowledgementLoading: false,
  updateSupplierPOAcknowledgementError: undefined,

  updateBuyerPOAcknowledgementLoading: false,
  updateBuyerPOAcknowledgementError: undefined,

  updatePortalOrderLineItemsLoading: false,
  updatePortalOrderLineItemsError: undefined,

  updatePortalOrderShippingConfirmationLoading: false,
  updatePortalOrderShippingConfirmationError: undefined,

  updateOrderUpdatedDeliveryDatesLoading: false,
  updateOrderUpdatedDeliveryDatesError: undefined,

  addOrderPartsLoading: false,
  addOrderPartsError: undefined,

  deleteShipmentPartLoading: false,
  deleteShipmentPartError: undefined,

  revisionProposalLoading: false,
  revisionProposalError: undefined,

  reviewRevisionProposalLoading: false,
  reviewRevisionProposalError: undefined,

  lineItemsTableSelectionModel: [],

  orderDetailRightTabOpen: true,

  orderActionPanelType: ActionPanelType.Default,

  uploadBulkFilesLoading: false,
  uploadBulkFilesError: undefined,

  fetchBulkFilesUploadReportLoading: false,
  fetchBulkFilesUploadReportError: undefined,
  bulkFilesUploadReport: undefined,
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    fetchOrdersRequest: (state, action: PayloadAction<FetchOrdersInput>) => {
      state.ordersLoading = true;
      state.ordersError = undefined;
      state.ordersLastQuery = action.payload?.query;
    },
    fetchOrdersFailure: (state, action: PayloadAction<Error>) => {
      state.ordersLoading = false;
      state.ordersError = action.payload;
      state.orders = [];
    },
    fetchOrdersSuccess: (
      state,
      action: PayloadAction<
        { results: Order[]; supplier_list?: OrderSupplier[] } & OrderCounts & {
            status_counts: OrderStateCounts;
          }
      >
    ) => {
      state.ordersLoading = false;
      state.ordersError = undefined;
      state.order = undefined;
      state.orders = action.payload.results;
      state.ordersCounts = {
        count: action.payload.count || 0,
        active_count: action.payload.active_count || 0,
        completed_count: action.payload.completed_count || 0,
      };
      state.ordersStatusCounts = {
        ...state.ordersStatusCounts,
        ...action.payload.status_counts,
      };
      state.ordersSuppliers = action.payload.supplier_list || [];
    },
    fetchSupplierLineItemsRequest: (state, action: PayloadAction<FetchOrdersInput>) => {
      state.supplierLineItemsLoading = true;
      state.supplierLineItemsError = undefined;
    },
    fetchSupplierLineItemsFailure: (state, action: PayloadAction<Error>) => {
      state.supplierLineItemsLoading = false;
      state.supplierLineItemsError = action.payload;
      state.supplierLineItems = [];
    },
    fetchSupplierLineItemsSuccess: (
      state,
      action: PayloadAction<
        { results: SupplierLineItem[] } & SupplierLineItemCounts & {
            status_count: SupplierLineItemStateCounts;
          }
      >
    ) => {
      state.supplierLineItemsLoading = false;
      state.supplierLineItemsError = undefined;
      state.supplierLineItems = action.payload.results;
      state.supplierLineItemsCounts = {
        count: action.payload.count || 0,
        active_count: action.payload.active_count || 0,
        shipped_count: action.payload.shipped_count || 0,
        cancelled_count: action.payload.cancelled_count || 0,
      };
      state.supplierLineItemsStatusCounts = {
        ...state.supplierLineItemsStatusCounts,
        ...action.payload.status_count,
      };
    },
    fetchOrderRequest: (state, action: PayloadAction<FetchOrderInput>) => {
      state.orderLoading = action?.payload?.refetchOrder ? false : true;
      state.orderError = undefined;
      state.uploadOrderFileLoading = false;
      state.lineItemsTableSelectionModel = [];
    },
    fetchOrderFailure: (state, action: PayloadAction<Error>) => {
      state.orderLoading = false;
      state.orderError = action.payload;
      state.order = undefined;
    },
    fetchOrderSuccess: (state, action: PayloadAction<Order>) => {
      state.orderLoading = false;
      state.orderError = undefined;
      state.order = action.payload;
    },
    createOrderRequest: (state, _action: PayloadAction<CreateOrderInput>) => {
      state.createOrderLoading = true;
      state.createOrderError = undefined;
    },
    createOrderFailure: (state, action: PayloadAction<Error>) => {
      state.createOrderLoading = false;
      state.createOrderError = action.payload;
    },
    createOrderSuccess: (state, action: PayloadAction<Order>) => {
      state.createOrderLoading = false;
      state.createOrderError = undefined;
    },
    updateOrderRequest: (state, _action: PayloadAction<Partial<UpdateOrderInput>>) => {
      state.updateOrderLoading = true;
      state.updateOrderError = undefined;
    },
    updateOrderFailure: (state, action: PayloadAction<Error>) => {
      state.updateOrderLoading = false;
      state.updateOrderError = action.payload;
    },
    updateOrderSuccess: (state, action: PayloadAction<Order>) => {
      state.updateOrderLoading = false;
      state.updateOrderError = undefined;
      state.order = {
        ...state.order,
        ...action.payload,
        supplier: action.payload.supplier || state.order?.supplier,
      };

      const { completed_count, active_count } = state.ordersCounts;
      const isCompleted = action?.payload?.is_completed;

      state.ordersCounts = {
        ...state.ordersCounts,
        completed_count: isCompleted ? completed_count + 1 : completed_count - 1,
        active_count: isCompleted ? active_count - 1 : active_count + 1,
      };
    },
    deleteOrderRequest: (state, _action: PayloadAction<DeleteOrderInput>) => {
      state.deleteOrderLoading = true;
      state.deleteOrderError = undefined;
    },
    deleteOrderFailure: (state, action: PayloadAction<Error>) => {
      state.deleteOrderLoading = false;
      state.deleteOrderError = action.payload;
    },
    deleteOrderSuccess: (state, _action: PayloadAction<Order>) => {
      state.deleteOrderLoading = false;
      state.deleteOrderError = undefined;
    },
    updateOrderFollowupRequest: (state, _action: PayloadAction<UpdateOrderFollowupInput>) => {
      state.updateOrderFollowupLoading = true;
      state.updateOrderFollowupError = undefined;
    },
    updateOrderFollowupFailure: (state, action: PayloadAction<Error>) => {
      state.updateOrderFollowupLoading = false;
      state.updateOrderFollowupError = action.payload;
    },
    updateOrderFollowupSuccess: (state, action: PayloadAction<OrderFollowUp[]>) => {
      state.updateOrderFollowupLoading = false;
      state.updateOrderFollowupError = undefined;
      state.order!.followups = state.order!.followups.map((followup) => {
        const findFollowUp = action.payload.find((item) => item.pk === followup.pk);

        if (findFollowUp) {
          return { ...followup, ...findFollowUp };
        }

        return followup;
      });
    },
    uploadOrderFileRequest: (state, _action: PayloadAction<UploadOrderFileInput>) => {
      state.uploadOrderFileLoading = true;
      state.uploadOrderFileError = undefined;
    },
    uploadOrderFileFailure: (state, action: PayloadAction<Error>) => {
      state.uploadOrderFileLoading = false;
      state.uploadOrderFileError = action.payload;
    },
    uploadOrderFileSuccess: (state, action: PayloadAction<any>) => {
      state.uploadOrderFileLoading = false;
      state.uploadOrderFileError = undefined;
      state.order!.files = [
        ...(state.order?.files || []),
        {
          ...action.payload[0],
          rev_number: state.order?.files?.length + 1 || 1,
        },
      ];
    },
    deleteOrderFileRequest: (state, _action: PayloadAction<DeleteOrderFileInput>) => {
      state.deleteOrderFileLoading = true;
      state.deleteOrderFileError = undefined;
    },
    deleteOrderFileFailure: (state, action: PayloadAction<Error>) => {
      state.deleteOrderFileLoading = false;
      state.deleteOrderFileError = action.payload;
    },
    deleteOrderFileSuccess: (state) => {
      state.deleteOrderFileLoading = false;
      state.deleteOrderFileError = undefined;
    },

    fetchPortalOrderRequest: (state, _action: PayloadAction<FetchPortalOrderInput>) => {
      state.portalOrderLoading = true;
      state.portalOrderError = undefined;
      state.lineItemsTableSelectionModel = [];
    },
    fetchPortalOrderFailure: (state, action: PayloadAction<Error>) => {
      state.portalOrderLoading = false;
      state.portalOrderError = action.payload;
      state.portalOrder = undefined;
    },
    fetchPortalOrderSuccess: (state, action: PayloadAction<Order>) => {
      state.portalOrderLoading = false;
      state.portalOrderError = undefined;
      state.portalOrder = action.payload;
    },

    updatePortalOrderRequest: (state, _action: PayloadAction<UpdatePortalOrderInput>) => {
      state.updatePortalOrderLoading = true;
      state.updatePortalOrderError = undefined;
      state.lineItemsTableSelectionModel = [];
    },
    updatePortalOrderFailure: (state, action: PayloadAction<Error>) => {
      state.updatePortalOrderLoading = false;
      state.updatePortalOrderError = action.payload;
    },
    updatePortalOrderSuccess: (state, action: PayloadAction<Order>) => {
      state.updatePortalOrderLoading = false;
      state.portalOrderError = undefined;
      state.portalOrder = { ...state.portalOrder, ...action.payload };
    },
    setLineItemsTableSelectionModel: (state, action: PayloadAction<GridSelectionModel>) => {
      state.lineItemsTableSelectionModel = action.payload;
    },
    setLineItemNewValue: (
      state,
      action: PayloadAction<{
        id?: number;
        newValue: any;
        field: LineItemType;
        isPortal?: boolean;
      }>
    ) => {
      const { id, newValue, field } = action.payload;
      const isNewField = (obj: any, field: string) => !(field in obj);

      const updateLineItems = (lineItems) => {
        return lineItems?.map((item) => {
          if (item.id === id) {
            const previousLineItem = item.previous_line_item ? { ...item.previous_line_item } : {};

            if (isNewField(previousLineItem, field)) {
              previousLineItem[field] = item[field];
            }

            const updatedItem = {
              ...item,
              [field]: newValue,
              previous_line_item: previousLineItem,
            };

            return updatedItem;
          }
          return item;
        });
      };

      const cleanPreviousLineItemsIfSameNewValue = (lineItems) => {
        return lineItems?.map((item) => {
          if (item.id === id && item.previous_line_item?.[field] == newValue) {
            const { [field]: _, ...rest } = item.previous_line_item;
            return {
              ...item,
              previous_line_item: rest,
            };
          }
          return item;
        });
      };

      if (action?.payload?.isPortal) {
        state.portalOrder!.line_items = updateLineItems(state.portalOrder!.line_items);
        state.portalOrder!.line_items = cleanPreviousLineItemsIfSameNewValue(
          state.portalOrder!.line_items
        );
      } else {
        state.order!.line_items = updateLineItems(state.order!.line_items);
        state.order!.line_items = cleanPreviousLineItemsIfSameNewValue(state.order!.line_items);
      }
    },
    resetUpdatedLineItems: (
      state,
      action: PayloadAction<{
        isPortal?: boolean;
      }>
    ) => {
      const resetLineItemFields = (lineItem: any) => {
        const previousLineItem = lineItem.previous_line_item;
        if (previousLineItem) {
          Object.keys(previousLineItem).forEach((field) => {
            if (lineItem[field] !== undefined) {
              lineItem[field] = previousLineItem[field];
            }
          });
          delete lineItem.previous_line_item;
        }
      };

      if (action.payload.isPortal) {
        state.portalOrder!.line_items = state.portalOrder!.line_items?.map((item) => {
          resetLineItemFields(item);
          return item;
        });
      } else {
        state.order!.line_items = state.order!.line_items?.map((item) => {
          resetLineItemFields(item);
          return item;
        });
      }
    },

    updateOrderDeliveryDatesRequest: (
      state,
      _action: PayloadAction<Partial<UpdateOrderDeliveryDatesInput>>
    ) => {
      state.updateOrderLineItemsLoading = true;
      state.updateOrderLineItemsError = undefined;
    },
    updateOrderDeliveryDatesFailure: (state, action: PayloadAction<Error>) => {
      state.updateOrderLineItemsLoading = false;
      state.updateOrderLineItemsError = action.payload;
    },
    updateOrderDeliveryDatesSuccess: (state, action: PayloadAction<Order>) => {
      state.updateOrderLineItemsLoading = false;
      state.updateOrderLineItemsError = undefined;
      state.order = action.payload;
      state.lineItemsTableSelectionModel = [];
    },

    updateOrderLineItemsRequest: (state, _action: PayloadAction<UpdateOrderLineItemsInput>) => {
      state.updateOrderLineItemsLoading = true;
      state.updateOrderLineItemsError = undefined;
    },
    updateOrderLineItemsFailure: (state, action: PayloadAction<Error>) => {
      state.updateOrderLineItemsLoading = false;
      state.updateOrderLineItemsError = action.payload;
    },
    updateOrderLineItemsSuccess: (state, action: PayloadAction<Order>) => {
      state.updateOrderLineItemsLoading = false;
      state.updateOrderLineItemsError = undefined;
      state.order = action.payload;
      state.lineItemsTableSelectionModel = [];
    },

    updateOrderTargetedProfilesRequest: (
      state,
      _action: PayloadAction<UpdateOrderTargetedProfilesInput>
    ) => {
      state.updateOrderTargetedProfilesLoading = true;
      state.updateOrderTargetedProfilesError = undefined;
    },
    updateOrderTargetedProfilesFailure: (state, action: PayloadAction<Error>) => {
      state.updateOrderTargetedProfilesLoading = false;
      state.updateOrderTargetedProfilesError = action.payload;
    },
    updateOrderTargetedProfilesSuccess: (state, action) => {
      state.updateOrderTargetedProfilesLoading = false;
      state.updateOrderTargetedProfilesError = undefined;
      if (!state.order) return;
      state.order.supplier.target_profiles = action.payload;
    },

    updatePortalOrderAcknowledgementRequest: (
      state,
      _action: PayloadAction<UpdatePortalOrderAcknowledgeInput>
    ) => {
      state.updatePortalOrderAcknowledgementLoading = true;
      state.updatePortalOrderAcknowledgementError = undefined;
    },
    updatePortalOrderAcknowledgementFailure: (state, action: PayloadAction<Error>) => {
      state.updatePortalOrderAcknowledgementLoading = false;
      state.updatePortalOrderAcknowledgementError = action.payload;
    },
    updatePortalOrderAcknowledgementSuccess: (state, action: PayloadAction<Order>) => {
      state.updatePortalOrderAcknowledgementLoading = false;
      state.updatePortalOrderAcknowledgementError = undefined;
      state.portalOrder = action.payload;
    },

    updateSupplierPOAcknowledgementRequest: (
      state,
      _action: PayloadAction<UpdatePortalOrderAcknowledgeInput>
    ) => {
      state.updateSupplierPOAcknowledgementLoading = true;
      state.updateSupplierPOAcknowledgementError = undefined;
    },
    updateSupplierPOAcknowledgementFailure: (state, action: PayloadAction<Error>) => {
      state.updateSupplierPOAcknowledgementLoading = false;
      state.updateSupplierPOAcknowledgementError = action.payload;
    },
    updateSupplierPOAcknowledgementSuccess: (state, action: PayloadAction<Order>) => {
      state.updateSupplierPOAcknowledgementLoading = false;
      state.updateSupplierPOAcknowledgementError = undefined;
      state.portalOrder = action.payload;
    },

    updateBuyerPOAcknowledgementRequest: (
      state,
      _action: PayloadAction<UpdatePortalOrderAcknowledgeInput>
    ) => {
      state.updateBuyerPOAcknowledgementLoading = true;
      state.updateBuyerPOAcknowledgementError = undefined;
    },
    updateBuyerPOAcknowledgementFailure: (state, action: PayloadAction<Error>) => {
      state.updateBuyerPOAcknowledgementLoading = false;
      state.updateBuyerPOAcknowledgementError = action.payload;
    },
    updateBuyerPOAcknowledgementSuccess: (state, action: PayloadAction<Order>) => {
      state.updateBuyerPOAcknowledgementLoading = false;
      state.updateBuyerPOAcknowledgementError = undefined;
      state.order = action.payload;
    },

    updatePortalOrderLineItemsRequest: (
      state,
      _action: PayloadAction<UpdatePortalOrderLineItemsInput>
    ) => {
      state.updatePortalOrderLineItemsLoading = true;
      state.updatePortalOrderLineItemsError = undefined;
    },
    updatePortalOrderLineItemsFailure: (state, action: PayloadAction<Error>) => {
      state.updatePortalOrderLineItemsLoading = false;
      state.updatePortalOrderLineItemsError = action.payload;
    },
    updatePortalOrderLineItemsSuccess: (state, action: PayloadAction<Order>) => {
      state.updatePortalOrderLineItemsLoading = false;
      state.updatePortalOrderLineItemsError = undefined;
      state.portalOrder = action.payload;
    },
    updateOrderShippingConfirmationRequest: (
      state,
      _action: PayloadAction<UpdateOrderShippingConfirmationInput>
    ) => {
      state.updatePortalOrderShippingConfirmationLoading = true;
      state.updatePortalOrderShippingConfirmationError = undefined;
    },
    updateOrderShippingConfirmationFailure: (state, action: PayloadAction<Error>) => {
      state.updatePortalOrderShippingConfirmationLoading = false;
      state.updatePortalOrderShippingConfirmationError = action.payload;
    },
    updateOrderShippingConfirmationSuccess: (
      state,
      action: PayloadAction<{
        order: Order;
        lineItemsIds: number[];
        isPortal: boolean;
        shippingNote?: string;
      }>
    ) => {
      state.updatePortalOrderShippingConfirmationLoading = false;
      state.updatePortalOrderShippingConfirmationError = undefined;

      let updatedOrder: Order = {
        ...action.payload.order,
        line_items: state.order?.line_items?.map((item) => {
          if (action.payload.lineItemsIds.includes(item.id)) {
            return {
              ...item,
              status: "shipped",
              shipment_note: action.payload.shippingNote,
            };
          }
          return item;
        }),
      };

      if (action.payload.isPortal) {
        state.portalOrder = { ...state.portalOrder, ...updatedOrder };
      } else {
        state.order = { ...state.order, ...updatedOrder };
      }
    },
    addOrderPartsRequest: (state, _action: PayloadAction<AddOrderPartsInput>) => {
      state.addOrderPartsLoading = true;
      state.addOrderPartsError = undefined;
    },
    addOrderPartsFailure: (state, action: PayloadAction<Error>) => {
      state.addOrderPartsLoading = false;
      state.addOrderPartsError = action.payload;
    },
    addOrderPartsSuccess: (state) => {
      state.addOrderPartsLoading = false;
      state.addOrderPartsError = undefined;
    },
    deleteShipmentPartRequest: (state, _action: PayloadAction<DeleteShipmentPartInput>) => {
      state.deleteShipmentPartLoading = true;
      state.deleteShipmentPartError = undefined;
    },
    deleteShipmentPartFailure: (state, action: PayloadAction<Error>) => {
      state.deleteShipmentPartLoading = false;
      state.deleteShipmentPartError = action.payload;
    },
    deleteShipmentPartSuccess: (state, action: PayloadAction<{ line_item_id: number }>) => {
      state.deleteShipmentPartLoading = false;
      state.deleteShipmentPartError = undefined;
      state.order.line_items = state.order.line_items?.filter(
        (item) => item.id !== action.payload.line_item_id
      );
      state.lineItemsTableSelectionModel = state.order.line_items
        ?.filter((item) => item.id !== action.payload.line_item_id)
        ?.map((item) => item.id);
    },
    updateOrderCollaborators: (state, action: PayloadAction<GenericUser[]>) => {
      state.order!.collaborators = action.payload;
    },

    fetchRevisionProposalRequest: (state, _action: PayloadAction<FetchOrderInput>) => {
      state.revisionProposalLoading = true;
      state.revisionProposalError = undefined;
    },
    fetchRevisionProposalFailure: (state, action: PayloadAction<Error>) => {
      state.revisionProposalLoading = false;
      state.revisionProposalError = action.payload;
    },
    fetchRevisionProposalSuccess: (state, action: PayloadAction<RevisionProposal[]>) => {
      state.revisionProposalLoading = false;
      state.revisionProposalError = undefined;
      state.order.revision_proposals = action.payload;
    },

    setLineItemsChangesFromRevisionProposal: (
      state,
      action: PayloadAction<OrderLineItemChanges[]>
    ) => {
      state.order.line_items_changes_proposal = action.payload;
    },

    approveOrRejectLineItemChange: (
      state,
      action: PayloadAction<{
        id: number;
        type: LineItemType;
        decision: "approved" | "rejected" | null;
      }>
    ) => {
      const { id, type, decision } = action.payload;
      const lineItemIndex = state.order.line_items_changes_proposal.findIndex(
        (item) => item.id === id
      );
      if (lineItemIndex !== -1) {
        const lineItem = state.order.line_items_changes_proposal[lineItemIndex];
        if (lineItem.decision) {
          lineItem.decision[type] = decision;
        } else {
          lineItem.decision = { [type]: decision };
        }
        state.order.line_items_changes_proposal[lineItemIndex] = lineItem;
      }
    },

    markAllLineItemChangeAs: (state, action: PayloadAction<"approved" | "rejected">) => {
      const decision = action.payload;
      state.order.line_items_changes_proposal = state.order.line_items_changes_proposal.map(
        (lineItem) => {
          if (lineItem.decision) {
            Object.keys(lineItem.decision).forEach((key) => {
              lineItem.decision[key as LineItemType] = decision;
            });
          } else {
            lineItem.decision = {
              [LineItemType.QUANTITY]: decision,
              [LineItemType.PRICE]: decision,
              [LineItemType.UPDATED_DELIVERY_DATE]: decision,
            };
          }
          return lineItem;
        }
      );
    },

    reviewRevisionProposalRequest: (state, _action: PayloadAction<ReviewRevisionProposalInput>) => {
      state.reviewRevisionProposalLoading = true;
      state.reviewRevisionProposalError = undefined;
    },
    reviewRevisionProposalFailure: (state, action: PayloadAction<Error>) => {
      state.reviewRevisionProposalLoading = false;
      state.reviewRevisionProposalError = action.payload;
    },
    reviewRevisionProposalSuccess: (state, action: PayloadAction<Order>) => {
      state.reviewRevisionProposalLoading = false;
      state.reviewRevisionProposalError = undefined;
      state.order = action.payload;
    },

    setOrderDetailRightTabOpen: (state, action: PayloadAction<boolean>) => {
      state.orderDetailRightTabOpen = action.payload;
    },

    setOrderActionPanelType: (state, action: PayloadAction<ActionPanelType>) => {
      state.orderActionPanelType = action.payload;
    },

    uploadBulkFilesRequest: (state, _action: PayloadAction<UploadBulkFilesInput>) => {
      state.uploadBulkFilesLoading = true;
      state.uploadBulkFilesError = undefined;
    },
    uploadBulkFilesFailure: (state, action: PayloadAction<Error>) => {
      state.uploadBulkFilesLoading = false;
      state.uploadBulkFilesError = action.payload;
    },
    uploadBulkFilesSuccess: (state) => {
      state.uploadBulkFilesError = undefined;
      // Continue loading status and stop loading when receiving STATUS=COMPLETED response from fetchBulkFilesUploadReportRequest
      state.uploadBulkFilesLoading = true;
    },

    fetchBulkFilesUploadReportRequest: (
      state,
      _action: PayloadAction<FetchBulkFilesUploadReportInput>
    ) => {
      state.fetchBulkFilesUploadReportLoading = true;
      state.fetchBulkFilesUploadReportError = undefined;
    },
    fetchBulkFilesUploadReportFailure: (state, action: PayloadAction<Error>) => {
      state.fetchBulkFilesUploadReportLoading = false;
      state.fetchBulkFilesUploadReportError = action.payload;
    },
    fetchBulkFilesUploadReportSuccess: (state, action: PayloadAction<any | undefined>) => {
      state.fetchBulkFilesUploadReportLoading = false;
      state.fetchBulkFilesUploadReportError = undefined;
      state.uploadBulkFilesLoading = action?.payload?.status === UploadReportStatus.IN_PROGRESS;
      state.bulkFilesUploadReport = action.payload;
    },

    resetPolling: () => {},
    resetBomFilesUploadReport: (state) => {
      state.bulkFilesUploadReport = undefined;
    },

    updateDraftOrderLineItemsRequest: (
      state,
      _action: PayloadAction<UpdateDraftOrderLineItemsInput>
    ) => {
      state.updateOrderLineItemsLoading = true;
      state.updateOrderLineItemsError = undefined;
    },
    updateDraftOrderLineItemsFailure: (state, action: PayloadAction<Error>) => {
      state.updateOrderLineItemsLoading = false;
      state.updateOrderLineItemsError = action.payload;
    },
    updateDraftOrderLineItemsSuccess: (state, _action: PayloadAction<Partial<OrderLineItem>[]>) => {
      state.updateOrderLineItemsLoading = false;
      state.updateOrderLineItemsError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state) => {
      state.uploadOrderFileLoading = false;
      state.updatePortalOrderAcknowledgementLoading = false;
      state.updatePortalOrderLineItemsLoading = false;
      state.updatePortalOrderShippingConfirmationLoading = false;
      state.updateOrderUpdatedDeliveryDatesLoading = false;
    });
  },
});

export const ordersActions = ordersSlice.actions;

export default ordersSlice.reducer;
