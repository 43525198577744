import AddIcon from "@mui/icons-material/Add";
import { Chip, TextField, Theme, Typography } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";
import { t } from "assets/configi18n/i18n";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FavoriteSupplierGroup, workspaceNextActions } from "../../redux";
import { selectFavoriteSupplierGroups } from "../../redux/selectors";

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    overflow: "auto",
    fontSize: theme.typography.body2.fontSize,
  },
  textInput: {
    minWidth: "200px",
  },
  option: { marginBottom: 0 },
  newOption: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  newOptionIcon: { color: theme.palette.primary.main },
  newOptionName: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  autoCompleteRoot: {
    display: "flex",
  },
  placeholder: {
    color: "red",
  },
  popper: {
    maxWidth: "300px",
    maxHeight: "300px",
  },
  addIcon: {
    fontSize: "1.2rem",
  },
}));

const filter = createFilterOptions();

type Props = {
  favoriteSupplierPk: number;
  initialValue: any[];
};

export const SelectSuppliersGroupAutocomplete: React.FC<Props> = ({
  favoriteSupplierPk,
  initialValue = [],
}) => {
  const classes = useStyles();
  const [value, setValue] = useState<any[]>(initialValue);
  const [editMode, setEditMode] = useState<boolean>(value?.length > 0);
  const favoriteSuppliersGroups = useSelector(selectFavoriteSupplierGroups);
  const hasAdminRole = useHasCompanyRole(["admin", "normal"]);
  const options = useMemo(
    () =>
      favoriteSuppliersGroups.filter(
        (group) => !initialValue.find((item) => item?.pk === group?.pk)
      ),
    [favoriteSuppliersGroups, initialValue]
  );

  const dispatch = useDispatch();

  const onClickAddToGroup = () => {
    setEditMode(true);
  };

  const onChange = useCallback(
    (_event: any, newValue: any) => {
      const newItem = newValue.find((item: any) => item?.isNew);

      if (newItem?.isNew) {
        // Create a new group from the user input
        dispatch(
          workspaceNextActions.createFavoriteSupplierGroupRequest({
            groupName: newItem?.inputValue,
            assignSupplierPk: favoriteSupplierPk,
            onSuccess: (group: FavoriteSupplierGroup) => {
              setValue([...value, group]);
            },
          })
        );
      } else {
        if (newValue?.length === 0) {
          setEditMode(false);
        }

        // Patch action to assign the supplier to the group
        dispatch(
          workspaceNextActions.updateFavoriteSupplierRequest({
            pk: favoriteSupplierPk,
            groups: newValue?.map((item: FavoriteSupplierGroup) => item?.pk),
          })
        );

        setValue(newValue);
      }
    },
    [favoriteSupplierPk, value]
  );

  const filterOptions = useCallback((options: any, params: any) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (params.inputValue !== "") {
      filtered.push({
        inputValue: params.inputValue,
        name: t("workspaceNext:supplierTable:inputs:createAGroupFor", {
          count: params.inputValue,
        }),
        isNew: true,
      });
    }

    return filtered;
  }, []);

  const getOptionLabel = useCallback((option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option || "";
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue || "";
    }
    // Regular option
    return option.name || "";
  }, []);

  const renderOption = useCallback((props: React.HTMLAttributes<HTMLLIElement>, option: any) => {
    if (option?.isNew) {
      return (
        <li {...props} className={`${props.className} ${classes.newOption}`}>
          <AddIcon className={classes.newOptionIcon} />
          <div className={classes.newOptionName}>{option.name}</div>
        </li>
      );
    } else {
      return (
        <li {...props}>
          <Typography>{option.name}</Typography>
        </li>
      );
    }
  }, []);

  const onBlur = () => (value?.length === 0 ? setEditMode(false) : null);

  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.stopPropagation();
    }
  };

  const renderInput = useCallback(
    (params) => (
      <TextField
        {...params}
        variant="standard"
        placeholder={hasAdminRole ? t("workspaceNext:supplierTable:inputs:typeToPickGroup") : ""}
        classes={{ placeholder: classes.placeholder }}
        InputProps={{
          ...params.InputProps,
          disableUnderline: true,
          className: classes.input,
          autoFocus: !editMode,
          onBlur,
          onKeyDown: handleKeyDown,
        }}
      />
    ),
    [value?.length]
  );

  const renderTags = useCallback(
    (tagValue, getTagProps) =>
      tagValue.map((option: any, index: number) => (
        <Chip {...getTagProps({ index })} label={option.isNew ? option.inputValue : option.name} />
      )),
    []
  );

  return (
    <>
      {!editMode ? (
        <Chip
          icon={<AddIcon className={classes.addIcon} />}
          label={t("workspaceNext:supplierTable:buttons:addToAGroup")}
          onClick={onClickAddToGroup}
          variant="outlined"
          color="default"
        />
      ) : (
        <Autocomplete
          readOnly={!hasAdminRole}
          size="small"
          id="select-suppliers-group-autocomplete"
          value={value}
          onChange={onChange}
          filterOptions={filterOptions}
          options={options}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          renderTags={renderTags}
          onBlur={onBlur}
          disableClearable
          classes={{
            root: classes.autoCompleteRoot,
            option: classes.option,
            popper: classes.popper,
            input: classes.textInput,
          }}
          autoHighlight
          multiple
          filterSelectedOptions
          selectOnFocus
          disableCloseOnSelect
          freeSolo
        />
      )}
    </>
  );
};
