export enum Questionnaire {
  PosEndUsers = "posEndUsersQuestionnaire",
  PomManufacturer = "pomManufacturerQuestionnaire",
  PomDistributor = "pomDistributorQuestionnaire",
}

export enum QuestionnaireType {
  pomManufacturerQuestionnaire = 1,
  pomDistributorQuestionnaire = 2,
  posEndUsersQuestionnaire = 3,
}

export type Step = {
  id: string;
  is_completed: boolean;
  is_partially_filled: boolean;
  last_updated: string;
  is_visible: boolean;
  is_active: boolean;
  step: number;
  company: string;
  step_order: number;
  step_name: string;
  approval_status: null | "changes_requested" | "changes_done" | "approved";
  unresolved_threads_count: number;
};

export const PosEndUsersFormKeyMapping = {
  Identification: "Identification",
  Approval: "Approval",
  Onboarding: "Onboarding",
  Scope: "Scope",
  A2psProcessControl: "A2PS’ Tests & Methods",
  LeadTimeForImplementation: "Lead time for implementation",
  LeadTimeForProduction: "Lead time for production",
  DesignAndBuild: "Design and build",
  Subtiers: "Subtiers",
  SubtiersOnboarding: "Subtiers onboarding",
};

export const PomManufacturerFormKeyMapping = {
  Identification: "Identification",
  Approval: "Approval",
  Onboarding: "Onboarding",
  Scope: "Scope",
  LeadTimeForImplementation: "Lead time for implementation",
  LeadTimeForProduction: "Lead time for production",
};

export const PomDistributorFormKeyMapping = {
  Identification: "Identification",
  Approval: "Approval",
  Onboarding: "Onboarding",
  Scope: "Scope",
  LeadTimeForImplementation: "Lead time for implementation",
  BufferStockStrategy: "Buffer stock strategy",
};

export enum QuestionnaireForms {
  Identification = "identificationForm",
  Approval = "approvalForm",
  Onboarding = "onboardingForm",
  Scope = "scopeForm",
  A2psProcessControl = "a2psProcessControlForm",
  LeadTimeForImplementation = "leadTimeForImplementationForm",
  LeadTimeForProduction = "leadTimeForProductionForm",
  DesignAndBuilt = "designAndBuiltForm",
  Subtiers = "subtiersForm",
  SubtiersOnboarding = "subtiersOnboardingForm",
  BufferStockStrategy = "bufferStockStrategyForm",
}

export enum PosEndUsersQuestionnaireForms {
  Identification = "identificationForm",
  Approval = "approvalForm",
  Onboarding = "onboardingForm",
  Scope = "scopeForm",
  A2psProcessControl = "a2psProcessControlForm",
  LeadTimeForImplementation = "leadTimeForImplementationForm",
  LeadTimeForProduction = "leadTimeForProductionForm",
  DesignAndBuild = "designAndBuiltForm",
  Subtiers = "subtiersForm",
  SubtiersOnboarding = "subtiersOnboardingForm",
}

export enum PomManufacturerForms {
  Onboarding = "onboardingForm",
  Approval = "approvalForm",
  Identification = "identificationForm",
  Scope = "scopeForm",
  LeadTimeForImplementation = "leadTimeForImplementationForm",
  LeadTimeForProduction = "leadTimeForProductionForm",
}

export enum PomDistributorForms {
  Identification = "identificationForm",
  Approval = "approvalForm",
  Onboarding = "onboardingForm",
  Scope = "scopeForm",
  LeadTimeForImplementation = "leadTimeForImplementationForm",
  BufferStockStrategy = "bufferStockStrategyForm",
}

export type WebinarLinkObj = {
  url: string;
  title: string;
};

export type WebinarLinkObjMap = {
  [key: string]: WebinarLinkObj;
};

export type IdentificationFormData = {
  company_id: string;
  title: string;
  address: string;
  manufacturing_site_address: string;
  vendor_code: string;
  cage_code: string;
  classification: number[];
  supplier_category: number;
  email_points_of_contact: string;
  has_subtiers?: boolean | null;
  additional_comment?: string;
  is_form_validated: boolean | null;
  webinar_link: WebinarLinkObjMap;
};

export type OnboardingFormData = {
  supplier_access_id: number;
  isupply: boolean | null;
  A2emm_001: boolean | null;
  am9000: boolean | null;
  aqpl_airbus: boolean | null;
  a220_asl_airbus: boolean | null;
  normaster: boolean | null;
  additional_comment?: string;
  company: string;
};

export const OnboardingRadioGroupKeysWithLabel = {
  isupply: "Access to Isupply?",
  A2emm_001: "Access to A2EMM-001 (EMCM)?",
  am9000: "Access to AM9000?",
  aqpl_airbus: "Access to AQPL Airbus.com?",
  a220_asl_airbus: "Access to A220 ASL Airbus.com?",
  normaster: "Access to Normaster?",
  icms: "Access to ICMS (Legal app BBD)?",
  icms2: "Access to ICMS2 (Legal app Airbus)?",
  eqsl_bbd: "Access to eQSL (Legal app BBD)?",
  eqsl_airbus: "Access to eQSL (Legal app Airbus)?",
  a220bok_plm002_bbd: "Access to A220BOK (PLM002) (Legal app BBD)?",
  acer_eadms_airbus: "Access to ACER (eADMS) (Legal app Airbus)?",
  bm7024_tool_plm025: "Access to BM7024 tool (PLM 025)?",
  ecra_plm063: "Access to eCRA (PLM063)?",
  elasticsearch_plm069: "Access to Elasticsearch (PLM069)?",
  mp_search_tool_plm128: "Access to M&P search tool (PLM128)?",
  psrm_plm163: "Access to PSRM (PLM163)?",
  edv8_bbd: "Access to EDV8 (Legal app BBD)?",
  edv8_airbus: "Access to EDV8 (Legal app Airbus)?",
  navigator_ietp_bbd: "Access to Navigator IETP (Legal app BBD)?",
  a220_ietp_portal_airbus: "Access to A220 IETP Portal (Legal app Airbus)?",
  cust_snags_bbd: "Access to Cust. Snags (Legal app BBD)?",
  clb_eqlb_airbus: "Access to CLB / eQLB (Legal app Airbus)?",
  aceri_bbd: "Access to ACERI (Legal app BBD)?",
  capital_a220_airbus: "Access to Capital A220 (Legal app Airbus)?",
  eroom_build_to_print: "Access to eRoom Build to Print (Legal app BBD)?",
  maps: "Access to MAPS (Legal app Airbus)?",
  eroom_gest_de_change: "Access to eRoom Gest. De Change?",
  rh1: "Access to RH1 (Legal app BBD)?",
  workday: "Access to Workday (Legal app Airbus)?",
  strategia: "Access to Strategia (Legal app BBD)?",
  peoplefluentlms_and_certification:
    "Access to PeopleFluentLMS & Certification (Legal app Airbus)?",
  efim_bbd: "Access to eFIM (Legal app BBD)?",
  efim_airbus: "Access to eFIM (Legal app Airbus)?",
  a220_supplier_collaboration: "Access to A220 Supplier Collaboration (Legal app BBD)?",
  arms_for_partners: "Access to ARMS for Partners (employee at the company) (Legal app BBD)?",
  csa_portal_or_customs: "Access to CSA Portal/Customs (Legal app BBD)?",
  datalink: "Access to Datalink (Legal app BBD)?",
  documentum_for_AHMS_and_LSAP_vault:
    "Access to Documentum for AHMS & LSAP Vault (v7) (Legal app BBD)?",
  econformity_ats: "Access to eConformity- ATS (Legal app BBD)?",
  econformity_sap: "Access to eConformity- SAP Corrective Action (Legal app BBD)?",
  econformity_vrnc: "Access to eConformity- VRNC (Legal app BBD)?",
  edimt: "Access to EDIMT (Legal app BBD)?",
  e_journal: "Access to eJournal (Legal app BBD)?",
  engineering_fracas: "Access to Engineering FRACAS (RELEX) (Legal app BBD)?",
  enovia_ecosystem: "Access to ENOVIA eco-system (CATIA, ENOVIA, eProduct, PDD) (Legal app BBD)?",
  q_checker: "Access to Q-Checker (Legal app BBD)?",
  sap_snc_qualtiy_cfolder: "Access to SAP SNC - Quality cFolder (Legal app BBD)?",
  secure_data_exchange: "Access to Secure Data Exchange (SDE) (Legal app BBD)?",
};

export const SubtierOnboardingColumnFieldsWithHeader = {
  is_upply: "Isupply",
  am9000: "AM9000",
  emcm: "A2EMM-001 (EMCM)",
  aqpl: "AQPL Airbus.com",
  normaster: "Normaster",
  A220asl: "A220 ASL Airbus.com",
  icms: "ICMS (Legal app BBD)",
  icms2: "ICMS2 (Legal app Airbus)",
  eqsl_bbd: "eQSL (Legal app BBD)",
  eqsl_airbus: "eQSL (Legal app Airbus)",
  a220bok_plm002_bbd: "A220BOK (PLM002) (Legal app BBD)",
  acer_eadms_airbus: "ACER (eADMS) (Legal app Airbus)",
  bm7024_tool_plm025: "BM7024 tool (PLM 025)",
  ecra_plm063: "eCRA (PLM063)",
  elasticsearch_plm069: "Elasticsearch (PLM069)",
  mp_search_tool_plm128: "M&P search tool (PLM128)",
  psrm_plm163: "PSRM (PLM163)",
  edv8_bbd: "EDV8 (Legal app BBD)",
  edv8_airbus: "EDV8 (Legal app Airbus)",
  navigator_ietp_bbd: "Navigator IETP (Legal app BBD)",
  a220_ietp_portal_airbus: "A220 IETP Portal (Legal app Airbus)",
  cust_snags_bbd: "Cust. Snags (Legal app BBD)",
  clb_eqlb_airbus: "CLB / eQLB (Legal app Airbus)",
  aceri_bbd: "ACERI (Legal app BBD)",
  capital_a220_airbus: "Capital A220 (Legal app Airbus)",
  eroom_build_to_print: "eRoom Build to Print (Legal app BBD)",
  maps: "MAPS (Legal app Airbus)",
  eroom_gest_de_change: "eRoom Gest. De Change",
  rh1: "RH1 (Legal app BBD)",
  workday: "Workday (Legal app Airbus)",
  strategia: "Strategia (Legal app BBD)",
  peoplefluentlms_and_certification: "PeopleFluentLMS & Certification (Legal app Airbus)",
  efim_bbd: "eFIM (Legal app BBD)",
  efim_airbus: "eFIM (Legal app Airbus)",
  a220_supplier_collaboration: "A220 Supplier Collaboration (Legal app BBD)",
  arms_for_partners: "ARMS for Partners (employee at the company) (Legal app BBD)",
  csa_portal_or_customs: "CSA Portal/Customs (Legal app BBD)",
  datalink: "Datalink (Legal app BBD)",
  documentum_for_AHMS_and_LSAP_vault: "Documentum for AHMS & LSAP Vault (v7) (Legal app BBD)",
  econformity_ats: "eConformity- ATS (Legal app BBD)",
  econformity_sap: "eConformity- SAP Corrective Action (Legal app BBD)",
  econformity_vrnc: "eConformity- VRNC (Legal app BBD)",
  edimt: "EDIMT (Legal app BBD)",
  e_journal: "eJournal (Legal app BBD)",
  engineering_fracas: "Engineering FRACAS (RELEX) (Legal app BBD)",
  enovia_ecosystem: "ENOVIA eco-system (CATIA, ENOVIA, eProduct, PDD) (Legal app BBD)",
  q_checker: "Q-Checker (Legal app BBD)",
  sap_snc_qualtiy_cfolder: "SAP SNC - Quality cFolder (Legal app BBD)",
  secure_data_exchange: "Secure Data Exchange (SDE) (Legal app BBD)",
};

export type ScopeFormData = {
  scope_id: number;
  specs_count: number;
  source_of_supply: string;
  manufacturing_site: string;
  company: string;
  specifications: number[];
  additional_comment?: string;
  scope_limitation?: string;
};

export type A2psProcessControlFormData = {
  process_control_id: number;
  has_captive_laboratory: boolean | null;
  additional_comment?: string;
  company: string;
};

export type LeadTimeForImplementationFormData = {
  lead_time_id: number;
  specification_integration?: boolean | null;
  road_map?: string;
  road_map_file?: {
    file_url: string;
    file_type: string;
    file_size: number;
    file_name: string;
  };
  additional_comment: string;
  company: string;
};

export type DesignAndBuiltFormData = {
  design_and_build_id: number;
  additional_comment?: string;
  company: string;
};

export type SubtierSupplier = {
  id: string;
  title: string;
};

export type SubtiersFormData = {
  subtier_id: number;
  company: string;
  subtier_suppliers: string[];
  subtier_suppliers_info: SubtierSupplier[];
  subtier_validation: boolean | null;
  additional_comment: string;
};

export type SubtiersOnboardingFormData = {
  confirmSubtierOnboarding: boolean | null;
  additional_comment?: string;
};

export type BufferStockStrategyFormData = {
  additional_comment?: string;
  company: string;
};

export type LeadTimeForProductionFormData = {
  additional_comment?: string;
};

export type ApprovalFormData = {} & FileObj[];

export type QuestionnaireState = {
  posEndUsersQuestionnaire: {
    name: Questionnaire.PosEndUsers;
    steps: Step[];
    lastSavedDate: string;
    activeFormStepIndex: number;
  };
  pomManufacturerQuestionnaire: {
    name: Questionnaire.PomManufacturer;
    steps: Step[];
    lastSavedDate: string;
    activeFormStepIndex: number;
  };
  pomDistributorQuestionnaire: {
    name: Questionnaire.PomDistributor;
    steps: Step[];
    lastSavedDate: string;
    activeFormStepIndex: number;
  };
  currentQuestionnaireName:
    | Questionnaire.PomDistributor
    | Questionnaire.PomManufacturer
    | Questionnaire.PosEndUsers;
  isThereUnsavedChangesInFormStep: boolean;
  isSupplierApproved: boolean | null;
  isFormDisplayForValidationManagement: boolean; // If vendor management is used for validation management, this will be true(is set true as prop in route config)
};

export type VendorManagementState = {
  formDataLoading: boolean;
  formDataError?: Error | undefined;
  formData:
    | IdentificationFormData
    | OnboardingFormData
    | ScopeFormData
    | A2psProcessControlFormData
    | LeadTimeForImplementationFormData
    | DesignAndBuiltFormData
    | SubtiersFormData
    | SubtiersOnboardingFormData
    | BufferStockStrategyFormData
    | LeadTimeForProductionFormData
    | ApprovalFormData;

  updateFormDataLoading: boolean;
  updateFormDataError: Error | undefined;

  fetchStepsLoading: boolean;
  fetchStepsError: Error | undefined;

  updateStepLoading: boolean;
  updateStepError: Error | undefined;

  processControlStandardsLoading: boolean;
  processControlStandardsError: Error | undefined;
  processControlStandardsData: InternationalStandardTableRow[] | null;

  allSpecificationsLoading: boolean;
  allSpecificationsError: Error | undefined;
  allSpecificationsData: Specification | null;

  deleteProcessControlStandardLoading: boolean;
  deleteProcessControlStandardError: Error | undefined;

  addProcessControlStandardLoading: boolean;
  addProcessControlStandardError: Error;

  updateProcessControlStandardLoading: boolean;
  updateProcessControlStandardError: Error;

  updateApprovalFormFileLoading: boolean;
  updateApprovalFormFileError: Error | undefined;

  deleteApprovalFormFileLoading: boolean;
  deleteApprovalFormFileError: Error | undefined;

  aclpClassificationsLoading: boolean;
  aclpClassificationsError: Error | undefined;
  aclpClassificationsData: AclpClassification[] | null;

  supplierCategoriesLoading: boolean;
  supplierCategoriesError: Error | undefined;
  supplierCategoriesData: SupplierCategory[] | null;

  specsOfScopeLoading: boolean;
  specsOfScopeError: Error | undefined;
  specsOfScopeData: FetchSpecsOfScopeResponse | null;

  removeSpecFromScopeLoading: boolean;
  removeSpecFromScopeError: Error | undefined;

  saveAdditionalCommentLoading: boolean;
  saveAdditionalCommentError: Error | undefined;

  addSpecToScopeLoading: boolean;
  addSpecToScopeError: Error | undefined;

  saveSpecOfScopeLoading: boolean;
  saveSpecOfScopeError: Error | undefined;

  updateLeadTimeImplFormDataLoading: boolean;
  updateLeadTimeImplFormDataError: Error | undefined;

  updateSubtierFormDataLoading: boolean;
  updateSubtierFormDataError: Error | undefined;

  subtierCompaniesLoading: boolean;
  subtierCompaniesError: Error | undefined;
  subtierCompaniesData: FetchSubtierCompaniesResponse | null;

  specsOfSubtierSupplierLoading: boolean;
  specsOfSubtierSupplierError: Error | undefined;
  specsOfSubtierSuppliersData: SpecOfSubtierSupplier[] | null;

  updateSpecOfSubtierSupplierLoading: boolean;
  updateSpecOfSubtierSupplierError: Error | undefined;

  deleteSpecOfSubtierSupplierLoading: boolean;
  deleteSpecOfSubtierSupplierError: Error | undefined;

  addSpecToSubtierSupplierLoading: boolean;
  addSpecToSubtierSupplierError: Error | undefined;

  subtierOnboardingTableDataLoading: boolean;
  subtierOnboardingTableDataError: Error | undefined;
  subtierOnboardingTableData: SubtierOnboardingTableRow[] | null;

  updateSubtiersOnboardingFormDataLoading: boolean;
  updateSubtiersOnboardingFormDataError: Error | undefined;

  updateSubtierOnboardingTableRowLoading: boolean;
  updateSubtierOnboardingTableRowError: Error | undefined;

  designAndBuiltDrawingsLoading: boolean;
  designAndBuiltDrawingsError: Error | undefined;
  designAndBuiltDrawingsData: DesignAndBuiltDrawingsResponse | null;

  udpateDesignAndBuiltDrawingLoading: boolean;
  udpateDesignAndBuiltDrawingError: Error | undefined;

  specsOfLeadTimeForProductionLoading: boolean;
  specsOfLeadTimeForProductionError: Error | undefined;
  specsOfLeadTimeForProductionData: FetchSpecsOfLeadTimeProduction | null;

  updateSpecOfLeadTimeForProductionLoading: boolean;
  updateSpecOfLeadTimeForProductionError: Error | undefined;

  specsOfBufferStockStrategyLoading: boolean;
  specsOfBufferStockStrategyError: Error | undefined;
  specsOfBufferStockStrategyData: FetchSpecsOfBufferStock | null;

  updateSpecOfBufferStockStrategyLoading: boolean;
  updateSpecOfBufferStockStrategyError: Error | undefined;

  suppliersLoading: boolean;
  suppliersError: Error | undefined;
  suppliersData: IdentificationFormData[] | null;

  suppliersSearchByCompanyIdLoading: boolean;
  suppliersSearchByCompanyIdError: Error | undefined;
  supplierCompanyDetails: IdentificationFormData | null;

  sendingSupportMessageLoading: boolean;
  sendingSupportMessageError: Error | undefined;

  identificationDataLoading: boolean;
  identificationDataError: Error | undefined;
  identificationData: IdentificationFormData | null;

  updateIdentificationDataLoading: boolean;
  updateIdentificationDataError: Error | undefined;

  // for validation management
  fetchQuestionnairesLoading: boolean;
  fetchQuestionnairesError: Error | undefined;
  questionnairesData: FetchQuestionnairesRequestResponse | null;

  fetchMessageThreadsOfStepLoading: boolean;
  fetchMessageThreadsOfStepError: Error | undefined;
  messageThreadsOfStepData: MessageThread[] | null;

  createNewMessageThreadLoading: boolean;
  createNewMessageThreadError: Error | undefined;

  createNewMessageWithinThreadLoading: boolean;
  createNewMessageWithinThreadError: Error | undefined;

  fetchMessagesOfThreadLoading: boolean;
  fetchMessagesOfThreadError: Error | undefined;
  messagesOfThreadData: MessageOfThread[] | null;

  triggerForFetchingMessagesOfThread: boolean;
  currentMessageThread: MessageThread | null;

  updateMessageThreadLoading: boolean;
  updateMessageThreadError: Error | undefined;

  updateApprovalStatusOfStepLoading: boolean;
  updateApprovalStatusOfStepError: Error | undefined;

  isSideDrawerOpen: boolean;
  isShowReviewBoxForThreadfulReview: boolean; // this is for threadful review, steps with table(except "subtiers onboarding") have threadful review

  updateApprovalStatusOfSpecLoading: boolean;
  updateApprovalStatusOfSpecError: Error | undefined;

  triggerForFetchingSpecsOfStep: boolean;

  fetchNumberOfUnapprovedLinesLoading: boolean;
  fetchNumberOfUnapprovedLinesError: Error | undefined;
  numberOfUnapprovedLines: number | null;

  isFetchSpecByClickedThread: boolean;
  nameOfTableToFetchSpecByClickedThread: string | null; // use for step that has multiple tables
} & QuestionnaireState;

export const FieldMandatoryText = "Mandatory";

export type AclpClassification = {
  id: number;
  name: string;
};

export type SupplierCategory = {
  id: number;
  name: string;
};

export type SetQuestionnaireFormStepData = {
  data:
    | IdentificationFormData
    | OnboardingFormData
    | ScopeFormData
    | A2psProcessControlFormData
    | LeadTimeForImplementationFormData
    | DesignAndBuiltFormData
    | SubtiersFormData
    | SubtiersOnboardingFormData
    | BufferStockStrategyFormData
    | LeadTimeForProductionFormData
    | ApprovalFormData;
  stepName:
    | QuestionnaireForms
    | PosEndUsersQuestionnaireForms
    | PomManufacturerForms
    | PomDistributorForms;
  fetcha2psRequest?: boolean;
};

export enum VendorManagementModalTypes {
  ADD_SPEC_LIST_MODAL = "add-spec-list-modal",
  THANK_YOU_MODAL = "thank-you-modal",
}

export type ScopeTableRow = {} & SpecificationTableRow;

export type AirbusSpecificationTableRow = {
  process_control_spec_id: number;
  specification_details: SpecificationTableRow;
  scope_limitation: string;
  company: string;
  specification: 2;
};

export type SpecificationTableRow = {
  specification_id: number;
  name: string;
  dash_ref: string;
  revision: string;
  controlled_process: string;
  description: string;
  specType: string;
  scope_limitation: string;
  evidence_file: EvidenceFileObj;
  evidence_comment: string;
  compliance_status: string;
  poe_per_line: string;
  ao_x_stock: number;
  bo_x_stock: number;
  coc_file?: EvidenceFileObj;
  coc_comment?: string;
  manufacturing_site?: string;
  source_of_supply?: Array<string>;
  approval_status: null | "changes_requested" | "changes_done" | "approved";
  entity_id: number;
  entity_name: string;
};

export type Spec = {
  scope_limitation?: string;
  evidence_file?: EvidenceFileObj;
  evidence_comment?: string;
  compliance_status?: string;
  poe_per_line?: string;
  ao_x_stock?: number;
  bo_x_stock?: number;
  manufacuring_site?: string;
  source_of_supply?: Array<string>;
  manufacturing_site?: string;
};

export type Specification = {
  count: number;
  results: SpecificationTableRow[];
};

export type InternationalStandardTableRow = {
  standard_id?: number;
  name?: string;
  dash_ref?: string;
  revision?: string;
  controlled_process?: string;
  description?: string;
  scope_limitation?: string;
  company?: string;
};

export enum Compliance {
  FullyCompliant = "fully_compliant",
  PartiallyCompliant = "partially_compliant",
  NotCompliant = "not_compliant",
  CompliantWithRFD = "compliant_with_rfd",
}

export type ActionPlan = {
  file: any;
  comment?: string;
};

export type LeadTimeForImplementationTableRow = {
  id: string;
  specification: string;
  dash: string;
  revision: string;
  controlledProcess: string;
  description: string;
  sourceOfSupply?: string[];
  manufacturingSite?: string[];
  compliance?: Compliance;
  poe?: string | null;
  actionPlan?: ActionPlan;
  a0xStock?: number;
  b0xStock?: number;
  coc?: ActionPlan;
};

export type DesignAndBuiltTableRow = {
  drawing_id: number;
  drawing_ref: string;
  drawing_title: string;
  revision: string;
  work_package_number: string;
  work_package_name: string;
  conform_to_a2x: string;
  poe: string;
  action_plan_evidence_file: EvidenceFileObj;
  action_plan_evidence_comment: string;
};

export type SubtierTableRow = {
  id: number;
  name: string;
  specification_id?: number;
  dash_ref: string;
  revision: string;
  controlled_process: string;
  description: string;
  compliance: string;
  poe: string;
};

export type SubtierOnboardingTableRow = {
  supplier_uuid: string;
  supplier_title: string;
  is_upply: boolean;
  am9000: boolean;
  emcm: boolean;
  aqpl: boolean;
  A220asl: boolean;
  normaster: boolean;
};

export type UpdateSubtierOnboardingTableRow = {
  supplierId: string;
  companyId?: string;
  data: {
    is_upply?: boolean;
    am9000?: boolean;
    emcm?: boolean;
    aqpl?: boolean;
    A220asl?: boolean;
    normaster?: boolean;
  };
};

export type BufferStockStrategyTableRow = {
  id: string;
  name: string;
  specification_id: string;
  dash_ref: string;
  revision: string;
  controlled_process: string;
  description: string;
  source_of_supply: string[];
  manufacturing_site: string[];
  ao_x_stock?: number;
  bo_x_stock?: number;
};

export type LeadTimeForProductionTableRow = {
  specification_id: string;
  name: string;
  dash_ref: string;
  revision: string;
  controlled_process: string;
  description: string;
  initial_industrialisation_capacity?: number;
  new_industrialisation_capacity?: number;
  changes_detail?: string;
  moq?: number;
  production_lead_time?: number;
};

export type SetQuestionnaireCompletedInfoByStepIndex = {
  stepIndex: number;
  completed: boolean;
};

export type FetchFormDataRequest = {
  formName:
    | QuestionnaireForms
    | PosEndUsersQuestionnaireForms
    | PomManufacturerForms
    | PomDistributorForms;
  companyId: string;
  fetcha2psRequest?: boolean;
  onSuccess?: () => void;
};

export type UpdateFormDataRequest = {
  data:
    | IdentificationFormData
    | OnboardingFormData
    | ScopeFormData
    | A2psProcessControlFormData
    | LeadTimeForImplementationFormData
    | DesignAndBuiltFormData
    | SubtiersFormData
    | SubtiersOnboardingFormData
    | BufferStockStrategyFormData
    | LeadTimeForProductionFormData
    | ApprovalFormData;
} & FetchFormDataRequest;

export type Pagination = {
  page?: number;
  pageSize?: number;
};

export type PaginationAndFilterForSpecification = {
  search?: string;
  specType?: string | string[];
  isPomDistributor?: boolean;
  stepId?: number;
  filter_completed?: boolean;
  supplier?: string;
  status?: keyof typeof ValidationManagementStatusEnum | "";
  entityId?: number;
} & Pagination;

export type DeleteProcessControlStandard = {
  companyId: string;
  standardId: number;
  onSuccess: () => void;
};

export type AddOrUpdateProcessControlStandard = {
  companyId: string;
  standard: InternationalStandardTableRow;
};

export type UpdateApprovalFormFile = {
  companyId: string;
  file: File | string;
  field_name: string;
  field_title: string;
};

export type FetchStepsRequest = {
  companyId: string;
  questionnaireType: number;
  onSuccess?: (steps: Step[]) => void;
};

export type FileObj = {
  file_id: string;
  field_name: string;
  field_title: string;
  file_size: number;
  file_name: string;
  file_url: string;
  file_type: string;
  company: string;
};

export type EvidenceFileObj = {
  file_type: string;
  file_size: number;
  file_name: string;
  file_url: string;
};

export type PaginationAndFilterForScopeSpecs = {
  companyId: string;
  search?: string;
  specType?: string | string[];
  isInPomDistributor?: boolean;
  pagination: Pagination;
  stepId: number;
  filter_completed: boolean;
  isForFetchingToGetCountData?: boolean;
  entityId?: number;
};

export type FetchSpecsOfScopeResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    data: ScopeTableRow[];
    total_count: number;
    completed_count: number;
    uncompleted_count: number;
  };
};

export type FetchSpecsOfScopeResponseWithPayload = {
  response: FetchSpecsOfScopeResponse;
  isForFetchingToGetCountData: boolean;
};

export type RemoveSpecFromScopeRequest = {
  specification_id: number;
  companyId: string;
  onSuccess?: () => void;
};

export type SaveAdditionalComment = {
  stepName:
    | QuestionnaireForms
    | PosEndUsersQuestionnaireForms
    | PomManufacturerForms
    | PomDistributorForms;
  additionalComment: string;
  companyId: string;
};

export type AddSpecToScopeRequest = {
  companyId: string;
  rows?: SpecificationTableRow[];
  row?: SpecificationTableRow;
  onSuccess?: () => void;
};

export type SaveSpecOfScopeRequest = {
  companyId: string;
  specificationId: number;
  spec: Spec;
  onSuccess?: () => void;
};

export type SaveSpecOfScopeSuccess = {
  specificationId: number;
  spec: Spec;
};

export type UpdateLeadTimeImplFormData = {
  formData:
    | {
        road_map?: string | File;
        road_map_file?: File;
        specification_integration?: boolean;
        additional_comment?: string;
      }
    | FormData;
  companyId: string;
};

export type FetchSpecsOfLeadTimeProductionInput = {
  companyId: string;
  page: number;
  pageSize: number;
  filter_completed: boolean;
  isForFetchingToGetCountData?: boolean;
  entityId?: number;
};

export type FetchSpecsOfLeadTimeProduction = {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    data: LeadTimeForProductionTableRow[];
    total_count: number;
    completed_count: number;
    uncompleted_count: number;
  };
};

export type FetchSpecsOfLeadTimeProductionResponseWithPayload = {
  response: FetchSpecsOfLeadTimeProduction;
  isForFetchingToGetCountData: boolean;
};

export type UpdateSubtierFormData = {
  formData: {
    subtier_validation?: boolean;
    additional_comment?: string;
    subtier_suppliers?: string[];
    subtier_suppliers_info?: SubtierSupplier[];
  };
  companyId: string;
};

export type FetchSpecsOfSubtier = {
  companyId: string;
  subtierId: string;
};

export type SubtierSupplierResponse = {
  company_id: string;
  title: string;
  address: string;
};

export type FetchSubtierCompaniesResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: SubtierSupplierResponse[];
};

export type SpecOfSubtierSupplier = {
  supplier_id: string;
  rows: SubtierTableRow[];
};

export type FetchSpecsOfSubtierSupplier = {
  companyId: string;
  supplierId: string;
  entityId?: number;
};

export type SpecOfSubtier = {
  compliance?: string;
  poe?: string;
};

export type UpdateSpecOfSubtierSupplier = {
  companyId: string;
  supplierId: string;
  specificationId: number;
  spec: SpecOfSubtier;
};

export type UpdateSpecOfSubtierSupplierSuccess = {
  supplierId: string;
  specificationId: number;
  spec: SpecOfSubtier;
};

export type DeleteSpecOfSubtierSupplier = {
  companyId?: string;
  supplierId: string;
  specificationId: number;
  onSuccess?: () => void;
};

export type AddSpecToSubtierSupplier = {
  companyId: string;
  supplierId: string;
  spec: {
    specification_id: number;
    poe: string | null;
    compliance: string | null;
  };
};

export type AddSpecToSubtierSupplierSuccess = {
  supplierId: string;
  spec: SubtierTableRow;
};

export type UpdateSubtiersOnboardingFormData = {
  formData: {
    confirmSubtierOnboarding: boolean;
    additional_comment: string;
  };
  companyId: string;
};

export type FetchSpecsOfStandardParams = {
  companyId: string;
  entityId?: number;
};

export type FetchDesignAndBuiltDrawings = {
  companyId: string;
  pagination: Pagination;
  filter_completed: boolean;
  isForFetchingToGetCountData?: boolean;
  entityId?: number;
};

export type DesignAndBuiltDrawingsResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    data: DesignAndBuiltTableRow[];
    total_count: number;
    completed_count: number;
    uncompleted_count: number;
  };
};

export type FetchDesignAndBuiltDrawingsResponseWithPayload = {
  response: DesignAndBuiltDrawingsResponse;
  isForFetchingToGetCountData: boolean;
};

export type UpdateDesignAndBuildDrawing = {
  companyId: string;
  drawingId: number;
  data: {
    conform_to_a2x?: string;
    poe?: string;
    action_plan_evidence_file?: File;
    action_plan_evidence_comment?: string;
  };
  onSuccess?: () => void;
};

export type UpdateDesignAndBuildDrawingSuccess = {
  drawingId: number;
  partOfRow: any;
};

export type FetchSpecsOfBufferStockInput = {
  companyId: string;
  pagination: Pagination;
  filter_completed: boolean;
  isForFetchingToGetCountData?: boolean;
  entityId?: number;
};

export type SupportMessagePayload = {
  subject: string;
  msg: string;
  attachments?: File[];
  email?: string;
};

export type SendSupportMessageInput = {
  companyId: string;
  data: SupportMessagePayload;
  onSuccess?: () => void;
};

export type FetchSpecsOfBufferStock = {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    data: BufferStockStrategyTableRow[];
    total_count: number;
    completed_count: number;
    uncompleted_count: number;
  };
};

export type FetchSpecsOfBufferStockResponseWithPayload = {
  response: FetchSpecsOfBufferStock;
  isForFetchingToGetCountData: boolean;
};

export const PaginationSizeForSpecs = 15;
export const PaginationSizeForFormTable = 10;

export type PartialSpecOfLeadTimeForProduction = {
  initial_industrialisation_capacity?: number;
  new_industrialisation_capacity?: number;
  changes_detail?: string;
  moq?: number;
  production_lead_time?: number;
};

export type UpdateSpecOfLeadTimeForProduction = {
  data: PartialSpecOfLeadTimeForProduction;
  companyId: string;
  specificationId: string;
  onSuccess?: () => void;
};

export type PartialSpecOfBufferStockStrategy = {
  ao_x_stock?: number;
  bo_x_stock?: number;
};

export type UpdateSpecOfBufferStockStrategy = {
  data: PartialSpecOfBufferStockStrategy;
  companyId: string;
  specificationId: string;
  onSuccess?: () => void;
};

export type PartialStep = {
  is_completed?: boolean;
  is_partially_filled?: boolean;
  is_visible?: boolean;
  is_active?: boolean;
};

export type UpdateStepRequest = {
  stepId: string;
  data: PartialStep;
  onSuccess?: () => void;
};

export type UpdateStepRequestSuccess = {
  stepId: string;
  data: Step;
  isForActivenessChange: boolean;
};

export type SelectedRow = {
  row: SpecificationTableRow;
  page: number;
};

export type UpdateIdentificationDataBody = {
  data: {
    is_form_validated: boolean;
  };
  companyId: string;
  onSuccess: () => void;
};

// for validation-management
export const QuestionnaireValidationUrlMap = {
  "POS End Users": "validationManagementReviewPosEndUsers",
  "POM Manufacturer": "validationManagementReviewPomManufacturer",
  "POM Distributors": "validationManagementReviewPomDistributor",
};

export const ValidationManagementStatus = {
  not_started: "Not started by supplier",
  in_progress_by_supplier: "Supplier completion in progress",
  ready_for_scqm: "Ready for SCQM review",
  review_required: "Review required from supplier",
  in_progress_by_scqm: "SCQM approbation in progress",
  approved: "Approved by SCQM",
};

export enum ValidationManagementStatusEnum {
  not_started = "Not started by supplier",
  in_progress_by_supplier = "Supplier completion in progress",
  ready_for_scqm = "Ready for SCQM review",
  review_required = "Review required from supplier",
  in_progress_by_scqm = "SCQM approbation in progress",
  approved = "Approved by SCQM",
}

export const ValidationManagementStatusType = {
  review_required: "warning",
  ready_for_scqm: "primary",
  approved: "success",
  not_started: "default",
  in_progress_by_supplier: "primary",
  in_progress_by_scqm: "primary",
};

export type ValidationManagementTableRow = {
  company: string;
  company_id: string;
  questionnaire_name: string;
  questionnaire_url: string;
  status: keyof typeof ValidationManagementStatusEnum;
  total_steps_count: number;
  approved_steps_count: number;
  completed_steps_count: number;
  is_reviewable: boolean;
  specs_count: number;
  drawings_count: number;
};

export type FetchQuestionnairesRequestInput = {
  page: number;
  pageSize: number;
  search: string;
  status: keyof typeof ValidationManagementStatusEnum | "";
};

export type FetchQuestionnairesRequestResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: ValidationManagementTableRow[];
};

export type FetchMessageThreadsOfStepParams = {
  companyId: string;
  stepId: number;
  onSuccess?: (messageThreads: MessageThread[]) => void;
};

export type MessageThread = {
  id: number;
  title: string | undefined;
  is_resolved: boolean | undefined;
  entity_id: number;
  entity_name: string;
  line_deleted?: boolean;
  supplier_title?: string;
};

export type CreateNewMessageThreadParams = {
  companyId: string;
  stepId: number;
  body: {
    title?: string;
    entity_id?: number;
    entity_name?: string;
    supplier_title?: string;
  };
  onSuccess: (messageThread: MessageThread) => void;
};

export type CreateNewMessageThreadSuccess = {
  stepId: number;
  messageThread: MessageThread;
};

export type CreateNewMessageWithinThreadParams = {
  messageThreadId: number;
  body: string;
  onSuccess: (messageThreadId: number) => void;
};

export type FetchMessagesOfThreadParam = {
  messageThreadId: number;
};

export type MessageOfThread = {
  id: number;
  body: string;
  created_at: string;
  author: string;
};

export type UpdateThreadStatusParams = {
  stepId: number;
  messageThreadId: number;
  isResolved: boolean;
  onSuccess?: () => void;
};

export type UpdateThreadStatusSuccess = {
  stepId: number;
  thread: MessageThread;
  isResolved: boolean;
};

export type UpdateApprovalStatusOfStepParams = {
  companyId: string;
  stepId: number;
  status: "changes_done" | "approved" | "changes_requested" | null;
};

export type UpdateApprovalStatusOfStepSuccess = {
  stepId: number;
  status: "changes_done" | "approved" | "changes_requested" | null;
};

export type UpdateApprovalStatusOfSpecParams = {
  companyId: string;
  stepId: number;
  body: {
    status: "changes_done" | "approved" | "changes_requested" | null;
    entity_id: number;
    entity_name: string;
  };
  onSuccess?: () => void;
};

export enum A2psThreadLabel {
  InternationalStandard = "Standard",
  ScopeSpecification = "Spec",
}

export type FetchNumberOfLinesApprovedParams = {
  companyId: string;
  stepId: number;
};
