import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Box, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import { Typography } from "@material-ui/core";

const StyledTypography = styled(CustomTypography)(({ theme }) => ({
  color: `${theme.palette.primary.main}`,
  letterSpacing: "0.4px",
}));

type EditableEvidenceTemplateCellProps = GridRenderCellParams & {
  fieldForCheckSelected: string;
  isTableDisabled: boolean;
};

export const EditableEvidenceTemplateCell: React.FC<EditableEvidenceTemplateCellProps> = ({
  fieldForCheckSelected,
  isTableDisabled,
  ...params
}) => {
  let text = null;
  const valueFromSelectionInRow = params.row[fieldForCheckSelected];

  if (
    valueFromSelectionInRow === "fully_compliant" ||
    valueFromSelectionInRow === "conform_to_a2x" ||
    valueFromSelectionInRow === null ||
    valueFromSelectionInRow === undefined
  ) {
    text = (
      <Typography color="textSecondary" variant="caption">
        {"Not required"}
      </Typography>
    );
  } else if (
    params.row["evidence_file"] ||
    params.row["evidence_comment"] ||
    params.row["action_plan_evidence_file"] ||
    params.row["action_plan_evidence_comment"] ||
    params.row["coc_file"] ||
    params.row["coc_comment"]
  ) {
    text = !isTableDisabled ? "Edit evidence" : "Evidence";
  } else if (
    (valueFromSelectionInRow &&
      (!(params.row["evidence_file"] || params.row["evidence_comment"]) ||
        !(
          params.row["action_plan_evidence_file"] || params.row["action_plan_evidence_comment"]
        ))) ||
    !(params.row["coc_file"] || params.row["coc_comment"])
  ) {
    text = !isTableDisabled ? "Add evidence" : "Evidence";
  }

  return (
    <Box sx={{ cursor: text ? "pointer" : "none" }}>
      <StyledTypography tooltipTitle={null} variant="caption">
        {text}
      </StyledTypography>
    </Box>
  );
};
